<script lang="ts" setup>
import { type PropType } from 'vue';

import { ModalContent, ModalFooter, modalVariant, type ModalVariant } from '@exchange/libs/modals/src';

defineProps({
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.light },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>

<template>
  <div class="user-blocked">
    <modal-content :variant="variant">
      <h4 class="user-blocked__title">{{ $t('modules.transactions.userBlocked.title') }}</h4>
      <i18n-t
        class="user-blocked__message"
        keypath="modules.transactions.userBlocked.text"
        tag="p"
        scope="global"
      >
        <template #linkText>
          <x-link-external :href="$t('modules.transactions.userBlocked.linkHref')">
            {{ $t('modules.transactions.userBlocked.linkText') }}
          </x-link-external>
        </template>
      </i18n-t>
    </modal-content>
    <modal-footer>
      <x-button
        class="user-not-verified__verify"
        variant="primary"
        :text="$t('fundamentals.ok')"
        @click="emit('close')"
      />
    </modal-footer>
  </div>
</template>
