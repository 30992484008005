import BigNumber from '@exchange/helpers/bignumber';
import getBaseQuote from '@exchange/helpers/get-base-quote';
import getPrecision from '@exchange/helpers/get-precision';
import type { OrderSide } from '@exchange/libs/order/shared-model/src/lib/order-essentials';

/* eslint-disable camelcase */
export interface BETradeModel {
  amount: string;
  amount_received?: string /** eotc specific */;
  instrument_code: string;
  price: string;
  side: OrderSide;
  time: string;
  trade_id: string;
}

export type FeeType = 'BEST' | 'STANDARD' | 'EOTC';

export interface BEFeeModel {
  collection_type: FeeType;
  fallback_reason?: BEFeeFallbackReasonModel;
  fee_amount: string;
  fee_currency: string;
}

export interface BETradeHistoryEntry {
  fee: BEFeeModel;
  trade: BETradeModel;
}

export enum FeeFallbackReasonType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  LOCKED = 'LOCKED',
  PRICING_UNAVAILABLE = 'PRICING_UNAVAILABLE',
}

export interface BEFeeFallbackReasonModel {
  best_balance: {
    available: string;
    locked: string;
    needed: string;
  };
  caused_by: FeeFallbackReasonType;
}
/* eslint-enable camelcase */
export interface FallbackReason {
  available?: BigNumber;
  locked?: BigNumber;
  needed?: BigNumber;
  reason?: FeeFallbackReasonType;
  tooltip: boolean;
}

export default class TradeModel {
  public collectionType: FeeType;

  public feeAmount: string;

  public feeCurrency: string;

  public amount: string;

  public amountReceived?: string;

  public instrumentCode: string;

  public price: string;

  public side: OrderSide;

  public time: Date;

  public id: string;

  public fallbackReason: FallbackReason;

  public get baseCurrencyCode() {
    const { base } = getBaseQuote(this.instrumentCode);

    return base;
  }

  public get quoteCurrencyCode() {
    const { quote } = getBaseQuote(this.instrumentCode);

    return quote;
  }

  public get marketName() {
    const { base, quote } = getBaseQuote(this.instrumentCode);

    return `${base}/${quote}`;
  }

  get total() {
    return new BigNumber(this.price).times(this.amount);
  }

  constructor({ trade, fee }: BETradeHistoryEntry) {
    this.amount = trade.amount;
    this.amountReceived = trade.amount_received;
    this.instrumentCode = trade.instrument_code;
    this.price = trade.price;
    this.side = trade.side;
    this.time = new Date(trade.time);
    this.id = trade.trade_id;

    this.collectionType = fee.collection_type;
    this.feeAmount = fee.fee_amount;
    this.feeCurrency = fee.fee_currency;
    this.fallbackReason = this.parseFallbackReason(fee.fallback_reason);
  }

  private getStringValuePrecision = (value: string) => {
    const afterDot = value.slice(value.indexOf('.') + 1);

    return afterDot.length;
  };

  public getFeePrecision = (feePrecision?: number) => getPrecision(this.feeAmount, feePrecision, true);

  public getAmountPrecision = (amountPrecision?: number) => {
    if (this.collectionType === 'EOTC') {
      return this.getStringValuePrecision(this.amount);
    }

    return getPrecision(this.amount, amountPrecision);
  };

  public getMarketPrecision = (marketPrecision?: number) => {
    if (this.collectionType === 'EOTC') {
      return this.getStringValuePrecision(this.price);
    }

    return getPrecision(this.price, marketPrecision);
  };

  public getAmountReceivedPrecision = () => this.getStringValuePrecision(this.amountReceived ?? '');

  public parseFallbackReason = (reason?: BEFeeFallbackReasonModel) => {
    if (!reason) {
      return {
        tooltip: false,
      };
    }

    if (reason.caused_by === FeeFallbackReasonType.PRICING_UNAVAILABLE) {
      return {
        tooltip: true,
        reason: FeeFallbackReasonType.PRICING_UNAVAILABLE,
      };
    }

    const available = new BigNumber(reason.best_balance.available);
    const locked = new BigNumber(reason.best_balance.locked);
    const needed = new BigNumber(reason.best_balance.needed);

    if (locked.gte(needed) && available.lt(1)) {
      return {
        tooltip: true,
        reason: FeeFallbackReasonType.LOCKED,
        available,
        locked,
        needed,
      };
    }

    if ((locked.lt(1) && available.lt(1)) || (available.gt(1) && needed.gt(locked.plus(available)))) {
      return {
        tooltip: true,
        reason: FeeFallbackReasonType.INSUFFICIENT_FUNDS,
        available,
        needed,
      };
    }

    return {
      tooltip: false,
    };
  };
}
