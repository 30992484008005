<script lang="ts" setup>
import debounce from 'lodash/debounce';
import { nanoid } from 'nanoid';
import { ref } from 'vue';

import { logger } from '@exchange/libs/utils/simple-logger/src';

import type { Toast } from './toast-manager';
import ToastWrapper from './ToastWrapper.vue';

const ordersMap: Map<string, (toast: Toast) => void> = new Map();

const props = defineProps({
  maxToastNumber: {
    type: Number,
    default: 6,
  },
});
const toasts = ref<Array<Toast & { internalId: string }>>([]);

const pushToast = (toast) => {
  toasts.value.push({
    ...toast,
    internalId: nanoid(),
  });
};

const handleOrderToasts = (toast: Toast) => {
  const orderId = toast.order as string;

  if (ordersMap.has(orderId)) {
    // if order is on map - get debounced function
    const pushOrderToast = ordersMap.get(orderId);

    if (pushOrderToast) {
      pushOrderToast(toast);
    }
  } else {
    // if order is NOT on map - set debounced function
    const pushOrderToast = debounce(pushToast, 400);

    ordersMap.set(orderId, pushOrderToast);
    pushOrderToast(toast);
  }
};

const handleOrderMapCleaning = (order?: string) => {
  if (order && ordersMap.has(order)) {
    ordersMap.delete(order);
  }
};

const removeToast = ($event) => {
  toasts.value = toasts.value.filter((t) => t.internalId !== $event.id);

  handleOrderMapCleaning($event.order);
};

/**
 * toast is an object with the following props
 * `content` - component for the toast content
 * `props` - properties of the content component
 *  `duration` - time before remove
 *  `order` - is order-related toast, this is order id
 */
const addToast = (toast: Toast) => {
  if (!toast.content) {
    logger.error('Nothing to show on toast');
  }

  requestAnimationFrame(() => {
    if (toasts.value.length >= props.maxToastNumber) {
      toasts.value.shift();
    }

    if ('order' in toast) {
      handleOrderToasts(toast);
    } else {
      pushToast(toast);
    }
  });
};

defineExpose({ addToast });
</script>

<template>
  <transition-group
    class="toasts-container"
    name="toasts"
    tag="div"
    testid="toast-container"
  >
    <div
      v-for="toast in toasts"
      :key="toast.internalId"
      class="toasts-container__wrapper"
      testid="toast-wrapper"
    >
      <toast-wrapper
        :toast="toast"
        @remove="removeToast($event)"
      ></toast-wrapper>
    </div>
  </transition-group>
</template>

<style lang="scss">
.toasts-container {
  position: fixed;
  z-index: var(--toast-container-z-index);
  right: 0;
  bottom: 0;
  display: flex;
  max-height: 100%;
  flex-flow: column wrap-reverse;
  align-items: flex-end;
  padding: 16px 10px;
  pointer-events: none;

  .xs & {
    align-items: center;
    padding-top: var(--safe-area-top);
    inset: 0 0 auto;
  }

  &__wrapper {
    width: 300px;
    margin-top: 16px;
    pointer-events: auto;
    transition: all 300ms ease-in-out;

    .xs & {
      position: absolute;
      width: 92vw;

      /**
      Requirements form designer:
      - only one (last) should be shown at a time
      - leave animation should only be applied for visible toast (last one)
      **/
      opacity: 0;

      /* stylelint-disable-next-line no-descending-specificity */
      &:last-child {
        opacity: 1;
      }
    }
  }
}

.toasts-enter-active,
.toasts-leave-active {
  z-index: -1;
  opacity: 1;
  transform: translateY(0);
}

.toasts-leave-active {
  position: absolute;
  right: 10px;
  bottom: 16px;
}

.toasts-enter-from,
.toasts-leave-to {
  opacity: 0.01;
  transform: translateY(50px);

  /* stylelint-disable-next-line no-descending-specificity */
  .xs & {
    transform: translateY(-50px);
  }
}
</style>
