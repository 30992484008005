<script lang="ts" setup>
import { BigNumber } from 'bignumber.js';
import { nanoid } from 'nanoid';
import { computed, ref, watch, type PropType } from 'vue';

import getBalance from '@exchange/helpers/balance';
import { ModalError, ModalFooter } from '@exchange/libs/modals/src';
import { type ModalVariant } from '@exchange/libs/modals/src';
import { type Subaccount, SubaccountType } from '@exchange/libs/trading-accounts/service/src';
import { AmountInput, TransactionPaymentContent, useCoverForBackend } from '@exchange/libs/transactions/shared/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

import SubaccountSelect, { type SubaccountOption } from './SubaccountSelect.vue';

export interface TransferFundsResponse {
  error: string | unknown | undefined;
}

export type TransferFundsCall = (data: {
  amount: string;
  currencySymbol: string;
  sourceId: string;
  sourceName: string;
  sourceType: SubaccountType;
  destinationId: string;
  destinationName: string;
  destinationType: SubaccountType;
}) => Promise<TransferFundsResponse>;

const props = defineProps({
  accounts: { type: Array as PropType<Array<Subaccount>>, required: true },
  currency: { type: Object as PropType<CurrencyModel | { symbol: string }>, required: true },
  preselectedFrom: { type: String, default: '' },
  preselectedTo: { type: String, required: false },
  shouldDisableSelect: { type: Boolean, required: true },
  transfer: { type: Function as PropType<TransferFundsCall>, required: true },
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const emit = defineEmits<{
  (e: 'cancel'): void;
}>();
const { loadingPendingTransactions, pendingAmount, warningMessage } = useCoverForBackend({ id: 'id' in props.currency ? props.currency.id : props.currency.symbol });

const preselection = props.preselectedFrom || CONSTANTS.MAIN_SUBACCOUNT_NAME;

const isMain = (v?: { name: string }) => v && v.name === CONSTANTS.MAIN_SUBACCOUNT_NAME;
const isInstant = (v?: { name: string }) => v && v.name === CONSTANTS.INSTANT_TRADE_ACCOUNT_NAME;

const amountValue = ref('');
const amountIsValid = ref(false);
const transferIsLoading = ref(false);
const updateAmountIsValid = (invalid: boolean) => {
  amountIsValid.value = !invalid;
};

const transactionPrecision = computed(() => ('transactionPrecision' in props.currency ? props.currency.transactionPrecision : 0));

const subaccountOptions = computed<Array<SubaccountOption>>(() =>
  props.accounts.map((subaccount) => {
    const correspondingBalance = subaccount.balances.find((balance) => balance.currencyCode === props.currency.symbol) || { available: new BigNumber(0), locked: new BigNumber(0) };

    const precision = transactionPrecision.value;

    return {
      displayValue: subaccount.name,
      displayRight: getBalance({ v: correspondingBalance.available, precision }),
      displayRightSymbol: props.currency.symbol,
      type: subaccount.type,
      name: subaccount.name,
      accountId: subaccount.accountId,
      correspondingBalance: {
        available: correspondingBalance.available,
        locked: correspondingBalance.locked,
        precision,
      },
      disabled: false,
    };
  }),
);
const fromSubaccount = ref(subaccountOptions.value.find((item) => item.name === preselection));
const toSubaccount = ref(
  props.preselectedTo
    ? subaccountOptions.value.find((item) => item.name === props.preselectedTo)
    : subaccountOptions.value.find((item) => item.name !== preselection && item.type !== SubaccountType.INSTANT_TRADE),
);
const shouldTargetOptionBeDisabled = (option: SubaccountOption) => {
  const sourceIsInstantTrade = isInstant(fromSubaccount.value);
  const sourceIsMain = isMain(fromSubaccount.value);

  let disabled = false;

  if (sourceIsInstantTrade) {
    disabled = true;

    if (isMain(option)) {
      disabled = false;
    }
  } else if (sourceIsMain) {
    disabled = false;
  } else if (fromSubaccount.value?.name === option.name) {
    disabled = true;
  } else {
    // source is sub-account
    disabled = false;

    if (isInstant(option)) {
      disabled = true;
    }
  }

  return disabled;
};

const subaccountOptionsTarget = computed(() => {
  const options = [...subaccountOptions.value].map((option) => ({
    ...option,
    disabled: shouldTargetOptionBeDisabled(option),
  }));

  return options;
});

const isOptionTargetSelectable = (option: SubaccountOption) => !option.disabled;
const fromMaxAmount = computed(() => fromSubaccount.value?.correspondingBalance.available ?? new BigNumber(0));
const fromSubaccountId = computed(() => fromSubaccount.value?.accountId ?? nanoid());

const amountIsGreaterThanAvailableCoverBackend = computed(() => {
  if (fromSubaccount.value && fromSubaccount.value.name === CONSTANTS.MAIN_SUBACCOUNT_NAME) {
    return new BigNumber(amountValue.value).isGreaterThan(fromMaxAmount.value.minus(pendingAmount.value));
  }

  return false;
});

const getMain = () => subaccountOptions.value.find((e) => e.name === CONSTANTS.MAIN_SUBACCOUNT_NAME);

watch(fromSubaccountId, () => {
  amountValue.value = '';
  updateAmountIsValid(true);
});

watch(subaccountOptions, () => {
  const findUpdatedValue = (accountId?: string) => subaccountOptions.value.find((item) => item.accountId === accountId);

  fromSubaccount.value = findUpdatedValue(fromSubaccount.value?.accountId);
  toSubaccount.value = findUpdatedValue(toSubaccount.value?.accountId);
});

const onFromSubaccountUpdate = () => {
  if (isInstant(fromSubaccount.value) && !isMain(toSubaccount.value)) {
    toSubaccount.value = getMain();
  }

  if (!isMain(fromSubaccount.value) && isInstant(toSubaccount.value)) {
    toSubaccount.value = getMain();
  }
};
const onToSubaccountUpdate = () => {
  if (isInstant(toSubaccount.value) && !isMain(fromSubaccount.value)) {
    fromSubaccount.value = getMain();
  }

  if (!isMain(toSubaccount.value) && isInstant(fromSubaccount.value)) {
    fromSubaccount.value = getMain();
  }
};

const getLockedFunds = (locked?: BigNumber, precision?: number) => {
  if (precision === undefined || !locked || locked.eq(0)) {
    return undefined;
  }

  return getBalance({ v: locked, precision });
};

const fromLockedFunds = computed(() => {
  const precision = fromSubaccount.value?.correspondingBalance.precision;
  const locked = fromSubaccount.value?.correspondingBalance.locked;

  return getLockedFunds(locked ? new BigNumber(locked) : undefined, precision);
});
const toLockedFunds = computed(() => {
  const precision = toSubaccount.value?.correspondingBalance.precision;
  const locked = toSubaccount.value?.correspondingBalance.locked;

  return getLockedFunds(locked ? new BigNumber(locked) : undefined, precision);
});
const transferPrecision = computed(() => fromSubaccount.value?.correspondingBalance.precision ?? transactionPrecision.value);

const isValid = computed(() => amountIsValid.value && !!fromSubaccount.value && !!toSubaccount.value && fromSubaccount?.value?.displayValue !== toSubaccount?.value?.displayValue);

const transferError = ref<TransferFundsResponse['error']>();

const transferInternal = async (from: SubaccountOption, to: SubaccountOption) => {
  if (transferIsLoading.value || !'no-account-id') {
    return;
  }

  transferIsLoading.value = true;
  transferError.value = undefined;

  const { error } = await props.transfer({
    amount: amountValue.value,
    currencySymbol: props.currency.symbol,
    sourceId: from.accountId,
    sourceName: from.name,
    sourceType: from.type,
    destinationId: to.accountId,
    destinationName: to.name,
    destinationType: to.type,
  });

  transferIsLoading.value = false;
  transferError.value = error;
};
</script>

<template>
  <div class="transfer-form">
    <!--  content -->
    <transaction-payment-content :variant="variant">
      <!--  from -->
      <section class="transfer-form__section transfer-form__section--from">
        <subaccount-select
          v-if="subaccountOptions"
          v-model:value="fromSubaccount"
          class="transfer-form__wallet-selector"
          name="source"
          :variant="variant"
          :options="subaccountOptions"
          :label="$t('modules.transactions.subaccountTransfer.transferFrom')"
          :currency="currency"
          :disabled="shouldDisableSelect"
          :locked-funds="fromLockedFunds"
          :precision="transactionPrecision"
          @update:value="onFromSubaccountUpdate"
        />
      </section>
      <!--  amount -->
      <section class="transfer-form__section transfer-form__section--amount">
        <amount-input
          :key="fromSubaccountId"
          v-model:value="amountValue"
          :variant="variant"
          :currency="currency"
          :precision="transferPrecision"
          :ignore-amount-too-small-check="true"
          :min-amount="0"
          :max-amount="fromMaxAmount"
          :stop-validation="false"
          translation-base-key="modules.transactions.subaccountTransfer"
          @validation-change="updateAmountIsValid($event)"
        />
      </section>
      <!--  to -->
      <section class="transfer-form__section transfer-form__section--to">
        <subaccount-select
          v-if="subaccountOptionsTarget"
          v-model:value="toSubaccount"
          class="transfer-form__wallet-selector"
          name="target"
          placeholder="Select subaccount"
          :variant="variant"
          :options="subaccountOptionsTarget"
          :label="$t('modules.transactions.subaccountTransfer.transferTo')"
          :currency="currency"
          :disabled="shouldDisableSelect"
          :locked-funds="toLockedFunds"
          :precision="transactionPrecision"
          :selectable="isOptionTargetSelectable"
          @update:value="onToSubaccountUpdate"
        />
        <ot-transition-fade>
          <modal-error
            v-if="transferError"
            :is-code="false"
            :error="transferError"
          />
          <modal-error
            v-if="amountIsGreaterThanAvailableCoverBackend"
            :is-code="false"
            :is-funds="false"
            :error="warningMessage"
          />
        </ot-transition-fade>
      </section>
    </transaction-payment-content>
    <!--  footer -->
    <modal-footer>
      <x-button
        variant="outline"
        :text="$t('modules.transactions.subaccountTransfer.buttons.cancel')"
        @click="emit('cancel')"
      />
      <x-button
        variant="primary"
        :disabled="!isValid || loadingPendingTransactions || amountIsGreaterThanAvailableCoverBackend"
        :loading="transferIsLoading"
        :text="$t('modules.transactions.subaccountTransfer.buttons.transfer')"
        @click="transferInternal(fromSubaccount, toSubaccount)"
      />
    </modal-footer>
  </div>
</template>

<style lang="scss">
.transfer-form {
  &__section {
    &--amount {
      margin-bottom: -4px;
    }
  }
}
</style>
