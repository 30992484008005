<script lang="ts" setup>
import { inject, ref, type PropType } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { type ModalVariant } from '@exchange/libs/modals/src';
import { type SubaccountType } from '@exchange/libs/trading-accounts/service/src';
import { LockedFunds } from '@exchange/libs/transactions/shared/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

export interface SubaccountOption {
  displayValue: string;
  displayRight: string;
  displayRightSymbol: string;
  type: SubaccountType;
  name: string;
  accountId: string;
  correspondingBalance: {
    available: BigNumber;
    locked: BigNumber;
    precision: number;
  };
  disabled: boolean;
}

defineProps({
  currency: { type: Object as PropType<CurrencyModel>, required: true },
  disabled: { type: Boolean, default: false },
  label: { type: String, required: true },
  lockedFunds: { type: String, required: false },
  name: { type: String, required: true },
  options: { type: Array as PropType<Array<SubaccountOption>>, required: true },
  placeholder: { type: String, default: '' },
  precision: { type: Number, required: true },
  selectable: { type: Function, required: false },
  value: { type: [Object, String], default: () => null },
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const emit = defineEmits<{
  (e: 'update:value', value: SubaccountOption): void;
}>();

const balanceMasked = inject('balanceMasked', ref(false));

const onItemClick = (value: SubaccountOption) => emit('update:value', value);
</script>

<template>
  <div
    class="subaccount-select"
    :class="{ 'subaccount-select--disabled': disabled }"
  >
    <label class="subaccount-select__label">{{ label }}</label>
    <ot-select
      class="subaccount-select__input"
      label="displayValue"
      :variant="variant === 'dark' ? 'dark-outlined' : 'light-outlined'"
      :menu-top-space="true"
      :options="options"
      :disabled="disabled"
      :value="value"
      :selectable="selectable"
      @update:value="onItemClick"
    >
      <template #selected-option="selected: SubaccountOption">
        <template v-if="selected">
          <div class="subaccount-select__item">
            <div class="truncate-text">{{ selected.displayValue }}</div>
            <div
              v-if="selected.displayRight"
              class="subaccount-select__item-right"
            >
              <ot-balance
                :masked="balanceMasked"
                :precision="selected.correspondingBalance.precision"
                :symbol="selected.displayRightSymbol"
                :value="selected.displayRight"
              />
            </div>
          </div>
        </template>
        <span
          v-else
          class="subaccount-select-selected__placeholder"
          >{{ placeholder }}</span
        >
      </template>
      <template #option="option: SubaccountOption">
        <div
          class="subaccount-select__item subaccount-select__list-option"
          :class="{ 'subaccount-select__item--disabled': option.disabled }"
        >
          <div class="truncate-text">{{ option.displayValue }}</div>
          <div
            v-if="option.displayRight"
            class="subaccount-select__item-right"
          >
            <ot-balance
              :masked="balanceMasked"
              :precision="option.correspondingBalance.precision"
              :symbol="option.displayRightSymbol"
              :value="option.displayRight"
            />
          </div>
        </div>
      </template>
    </ot-select>
    <locked-funds
      v-if="lockedFunds"
      class="subaccount-select__sub-text"
      :currency="currency.symbol"
      :masked="balanceMasked"
      :precision="precision"
      :value="lockedFunds"
      :variant="variant"
    />
  </div>
</template>

<style lang="scss">
.subaccount-select {
  &__input {
    height: var(--input-height);
  }

  &__item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    &--disabled {
      color: rgb(var(--v-theme-text-tertiary));
      cursor: default;
    }
  }

  &__item-right {
    text-align: right;
    white-space: nowrap;
  }

  &__list-option {
    padding: 4px;
  }

  &__sub-text {
    margin-top: 3px;
  }
}
</style>
