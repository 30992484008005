<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

type Variant = 'default' | 'failed' | 'success' | 'warning' | 'primary' | 'secondary';

interface SimpleToastText {
  tKey: string;
  tKeyParams: Dictionary<string | boolean | number>;
}

const props = defineProps({
  iconName: { type: String, required: false },
  message: { type: [String, Object] as PropType<string | SimpleToastText>, required: false },
  showIcon: { type: Boolean, default: true },
  title: { type: [String, Object] as PropType<string | SimpleToastText>, required: false },
  translatedMessage: { type: String, required: false },
  variant: { type: String as PropType<Variant>, default: 'default' },
});
const emit = defineEmits<{
  (e: 'remove'): void;
}>();

const { t } = useI18n({ useScope: 'global' });

const titleToShow = computed(() => {
  if (props.title === undefined) {
    return undefined;
  }

  if (typeof props.title === 'string') {
    return t(props.title);
  }

  return props.title.tKeyParams ? t(props.title.tKey, props.title.tKeyParams) : t(props.title.tKey);
});

const messageToShow = computed(() => {
  if (props.message === undefined) {
    return undefined;
  }

  if (typeof props.message === 'string') {
    return t(props.message);
  }

  return props.message.tKeyParams ? t(props.message.tKey, props.message.tKeyParams) : t(props.message.tKey);
});

const icon = computed(() => {
  if (!props.showIcon) {
    return undefined;
  }

  if (props.iconName !== undefined) {
    return props.iconName;
  }

  switch (props.variant) {
    case 'warning':
      return 'info-circle';
    case 'failed':
      return 'danger';
    case 'success':
      return 'tick-circle-bulk';
    case 'default':
    default:
      return 'info-circle-bulk';
  }
});
</script>

<template>
  <div
    class="simple-toast"
    :class="[`simple-toast--${props.variant}`]"
    :testid="`toast-element-${variant}`"
  >
    <div
      class="simple-toast__left"
      :class="{ 'simple-toast__left--full-size': showIcon }"
    >
      <ot-icon
        v-if="showIcon && icon"
        class="simple-toast__icon"
        :name="icon"
      />
    </div>
    <div class="simple-toast__content">
      <div class="simple-toast__title">
        <template v-if="$slots.title">
          <slot name="title" />
        </template>
        <template v-else>{{ titleToShow }}</template>
      </div>
      <div class="simple-toast__message">
        <template v-if="$slots.message"> <slot name="message" /> </template><template v-else>{{ translatedMessage || messageToShow }}</template>
      </div>
    </div>
    <div class="simple-toast__close">
      <x-button
        variant="clear"
        size="text-like"
        @click="emit('remove')"
      >
        <ot-icon
          class="simple-toast__close-icon"
          name="close"
          :inherit-fill="true"
          :inherit-stroke="true"
        />
      </x-button>
    </div>
  </div>
</template>

<style lang="scss">
.simple-toast {
  --toast-background-color: rgb(var(--v-theme-elevation-2));
  --toast-text-color: rgb(var(--v-theme-text-secondary));

  display: flex;
  overflow: hidden;
  min-height: 58px;
  background-color: var(--toast-background-color);
  color: var(--toast-text-color);

  &__left {
    display: flex;
    min-width: 8px;
    align-items: center;
    justify-content: center;
    background-color: var(--toast-variant-color);

    &--full-size {
      width: 36px;
    }
  }

  &__icon {
    color: rgb(var(--v-theme-text-primary));
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
  }

  &__title {
    color: rgb(var(--v-theme-text-primary));
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
  }

  &__message {
    color: var(--toast-text-color);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-regular);
  }

  &__close {
    padding: 4px;

    &-icon {
      width: 16px;
      color: rgb(var(--v-theme-text-secondary));
    }
  }
}

.simple-toast--failed {
  --toast-variant-color: rgb(var(--v-theme-error));
}

.simple-toast--success {
  --toast-variant-color: rgb(var(--v-theme-success));
}

.simple-toast--warning {
  --toast-variant-color: rgb(var(--v-theme-warning));
}

.simple-toast--default {
  --toast-variant-color: rgb(var(--v-theme-elevation-3));
}

.simple-toast--primary {
  --toast-variant-color: rgb(var(--v-theme-primary));
}

.simple-toast--secondary {
  --toast-variant-color: rgb(var(--v-theme-secondary));
}
</style>
