<script lang="ts" setup>
import { defineAsyncComponent, shallowRef, type PropType } from 'vue';

import { accountService } from '@exchange/libs/account/service/src';
import { usePromiseWithCB } from '@exchange/libs/composables/shared/src/lib/usePromise';
import { modalService, type ModalVariant } from '@exchange/libs/modals/src';

const ErrorModal = defineAsyncComponent(() => import('@exchange/libs/modals/src/lib/common/ErrorModal.vue'));

const props = defineProps({
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const {
  loading,
  error,
  createPromise: getData,
} = usePromiseWithCB(
  async () => {
    await accountService.getAccountId({ useSubaccount: false });
    await Promise.all([accountService.fetchHolderUser(), accountService.fetchFees()]);
  },
  false,
  () => {
    modalService.substitute(
      shallowRef(ErrorModal),
      {
        variant: props.variant,
        titleKey: 'fundamentals.unavailableError.title',
        messageKey: 'fundamentals.unavailableError.text',
      },
      {
        'x-error-close': () => {
          modalService.clear();
        },
      },
      { variant: props.variant },
    );
  },
);

getData();
</script>

<template>
  <div class="fetch-bitpanda-user">
    <div
      v-if="loading"
      class="fetch-bitpanda-user__loading"
      testid="user-loading"
    >
      <ot-spinner :black="variant === 'light'" />
    </div>
    <slot v-if="!loading && !error" />
  </div>
</template>

<style lang="scss">
.fetch-bitpanda-user {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  .xs & {
    height: 100%;
  }

  &__loading,
  &__error {
    margin-top: 25px;
    margin-bottom: 55px;
  }

  &__loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
</style>
