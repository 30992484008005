<script lang="ts" setup>
import SimpleToast from '../SimpleToast.vue';

const emit = defineEmits<{
  (e: 'remove'): void;
}>();
</script>

<template>
  <simple-toast
    icon-name="info-circle-bulk"
    title="modules.myOrders.toasts.stopOrder.title"
    message="modules.myOrders.toasts.stopOrder.message"
    @remove="emit('remove')"
  />
</template>
