<script lang="ts" setup>
import { values, throttle } from 'lodash/fp';
import { computed, onMounted, ref, watch, type PropType } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { type BalanceModel } from '@exchange/libs/balances/service/src';
import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

import CoinSelectItem from './CoinSelectItem.vue';

const DialogType = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  TRANSFER: 'transfer',
} as const;

type Dialog = (typeof DialogType)[keyof typeof DialogType];

interface BalanceCurrencyPair {
  currency: CurrencyModel;
  balance: BigNumber;
}

const props = defineProps({
  balances: { type: Array as PropType<Array<BalanceModel>> },
  currencies: { type: Array as PropType<Array<CurrencyModel>> },
  defaultFiatCurrency: { type: String, required: true },
  selectedCurrency: { type: Object as PropType<CurrencyModel> },
  type: { type: String as PropType<Dialog>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.light },
  zeroBalanceSelectable: { type: Boolean, default: true },
  fiatSelectable: { type: Boolean, default: true },
});

const emit = defineEmits<{
  (e: 'update:selectedCurrency', v: CurrencyModel | undefined): void;
}>();

const coinSelectEl = ref<HTMLElement>();
const contentEl = ref();

const isWithdraw = computed(() => props.type === DialogType.WITHDRAW);
const isTransfer = computed(() => props.type === DialogType.TRANSFER);

const throttledBalanceToUse = ref(props.balances);

const getFiatValue = (pair: BalanceCurrencyPair) => pair.balance.times(pair.currency.defaultFiatValue || 0);
const sortByCurrencyName = (a: BalanceCurrencyPair, b: BalanceCurrencyPair) => {
  if (!a.currency.name && b.currency.name) {
    return 1;
  }

  if (!b.currency.name && a.currency.name) {
    return -1;
  }

  if (!a.currency.name || !b.currency.name) {
    return a.currency.id.localeCompare(b.currency.id);
  }

  return a.currency.name.localeCompare(b.currency.name);
};
const sortByBalance = (a: BalanceCurrencyPair, b: BalanceCurrencyPair) => b.balance.minus(a.balance).toNumber();
const sortByFiatValue = (a: BalanceCurrencyPair, b: BalanceCurrencyPair) => getFiatValue(b).minus(getFiatValue(a)).toNumber();
const sortByPreselectedCurrency = (selectedCurrencyId: string | undefined) => (a: BalanceCurrencyPair, b: BalanceCurrencyPair) => {
  if (selectedCurrencyId) {
    if (selectedCurrencyId === a.currency.id) {
      return -1;
    }

    if (selectedCurrencyId === b.currency.id) {
      return 1;
    }
  }

  return 0;
};

const updateThrottledBalance = (balances: Array<BalanceModel> | undefined) => {
  throttledBalanceToUse.value = balances;
};

const currencyBalancePairs = computed(() => {
  const pairs: Array<BalanceCurrencyPair> = values(props.currencies)
    .map((currency: CurrencyModel) => {
      const currentBalances = throttledBalanceToUse.value;

      const findByCurrencyId = <T extends { currencyCode: string }>(arr: Array<T>) => arr.find((e) => currency.id === e.currencyCode);

      if (currentBalances) {
        const cb: Array<(typeof currentBalances)[number]> = currentBalances;
        const balance = findByCurrencyId(cb);

        if (balance) {
          const total = balance.getTotal();
          const balanceIsTooSmall = currency.checkAmountIsTooSmall(total);

          return {
            currency,
            balance: balanceIsTooSmall ? new BigNumber(0) : total,
            shown: true,
          };
        }
      }

      return {
        currency,
        balance: new BigNumber(0),
        shown: true,
      };
    })
    .filter((p) => p.shown);

  pairs.sort(sortByCurrencyName);

  if (isWithdraw.value || isTransfer.value) {
    pairs.sort(sortByBalance);
    pairs.sort(sortByFiatValue);
  }

  return pairs.sort(sortByPreselectedCurrency(props.selectedCurrency?.id));
});

const onItemClick = (pair: BalanceCurrencyPair) => {
  if (!props.fiatSelectable && CONSTANTS.FIAT_CURRENCIES.includes(pair.currency.id)) {
    return;
  }
  if (!props.zeroBalanceSelectable && pair.balance.isZero()) {
    return;
  }
  emit('update:selectedCurrency', props.selectedCurrency ? undefined : pair.currency);
};

watch(
  () => props.balances,
  throttle(60_000, (newVal) => updateThrottledBalance(newVal)),
);

onMounted(() => {
  if (!coinSelectEl.value || !contentEl.value) {
    return;
  }

  if (props.selectedCurrency) {
    if (contentEl.value) {
      contentEl.value.scrollTop = 0;
    }
  }
});
</script>

<template>
  <div
    ref="coinSelectEl"
    class="coin-select"
    :class="[`coin-select--${variant}`]"
  >
    <div class="coin-select__header">
      <div class="coin-select__header-left">
        {{ $t('modules.transactions.currencySelector.headline.selectCurrency') }}
      </div>
      <div
        v-if="isWithdraw"
        class="coin-select__header-right"
      >
        {{ $t('modules.transactions.currencySelector.headline.amountAndFiatValue', { fiat: defaultFiatCurrency }) }}
      </div>
    </div>
    <div
      ref="contentEl"
      class="coin-select__content"
      :class="{ collapsed: !!selectedCurrency, single: currencyBalancePairs.length === 1 }"
    >
      <transition-group
        name="coin-list"
        :appear="true"
      >
        <coin-select-item
          v-for="pair in currencyBalancePairs"
          :key="pair.currency.id"
          class="coin-select__item"
          :class="{
            'coin-select__item--disabled': (!zeroBalanceSelectable && pair.balance.isZero()) || (!fiatSelectable && CONSTANTS.FIAT_CURRENCIES.includes(pair.currency.id)),
          }"
          :is-selected="selectedCurrency === pair.currency"
          :is-withdraw="isWithdraw"
          :variant="variant"
          :currency="pair.currency"
          :balance="pair.balance"
          @click.stop="onItemClick(pair)"
        />
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss">
.coin-select {
  --coin-select-max-height: 85vh;
  --coin-select-color: rgb(var(--v-theme-text-secondary));
  --coin-select-header-left-color: var(--text-0);
  --coin-select-header-content-gradient: rgb(255 255 255 / 0%) -20px, var(--elevation-light-0);

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  color: var(--coin-select-color);

  &__header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;

    &-left {
      color: var(--coin-select-header-left-color);
      font-weight: var(--font-weight-medium);
    }
  }

  --item-height: 50px;
  --item-gap: 4px;

  &__item {
    height: var(--item-height);

    &--disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__content {
    position: relative;
    display: flex;
    overflow: auto;
    max-height: var(--coin-select-max-height);
    flex: 1;
    flex-direction: column;
    padding-bottom: calc(0.5 * var(--item-height));
    gap: var(--item-gap);
    transition: all 300ms ease-in-out;
    will-change: auto;

    &.collapsed:not(.single) {
      overflow: hidden;
      max-height: calc(1.5 * var(--item-height));

      &::after {
        position: absolute;
        z-index: 10;
        right: 0;
        bottom: calc(-1 * var(--item-gap));
        left: 0;
        height: calc(0.5 * var(--item-height));
        background: linear-gradient(var(--coin-select-header-content-gradient));
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        content: '';
        pointer-events: none;
        transition: all 300ms ease-in-out;
      }

      .coin-select-item:nth-of-type(2) {
        opacity: 0;
        transition: all 300ms ease-in-out;
      }

      &:hover {
        $visible-part-ration: 0.9;

        max-height: calc((1 + $visible-part-ration) * var(--item-height));

        &::after {
          height: calc($visible-part-ration * var(--item-height));

          /* Safari has extreme opinions what transparent looks like... */
          background: linear-gradient(var(--coin-select-header-content-gradient));
        }

        .coin-select-item:nth-of-type(2) {
          opacity: 1;
        }
      }
    }
  }
}

.coin-list-move, /* apply transition to moving elements */
.coin-list-enter-active,
.coin-list-leave-active {
  transition: all 0.3s ease;
}

.coin-list-enter-from,
.coin-list-leave-to {
  opacity: 0;
  transform: translateY(15px);
}

/* ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
.coin-list-leave-active {
  position: absolute;
}

.coin-select--dark {
  --coin-select-color: rgb(var(--v-theme-text-secondary));
  --coin-select-header-left-color: rgb(var(--v-theme-text-primary));
  --coin-select-header-content-gradient: rgb(255 255 255 / 0%) -20px, rgb(var(--v-theme-elevation-2));
}
</style>
