<script lang="ts" setup>
import SimpleToast from '../SimpleToast.vue';

const emit = defineEmits<{
  (e: 'remove'): void;
}>();
</script>

<template>
  <simple-toast
    variant="success"
    icon-name="tick-circle-bulk"
    title="modules.myOrders.toasts.cancelled.title"
    message="modules.myOrders.toasts.cancelled.message"
    @remove="emit('remove')"
  />
</template>
