<script lang="ts" setup>
import { computed, ref, type PropType } from 'vue';
import { inject } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { BalanceModel } from '@exchange/libs/balances/service/src';
import { type ModalVariant } from '@exchange/libs/modals/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

import LockedFunds from './LockedFunds.vue';

const props = defineProps({
  balance: { type: Object as PropType<BalanceModel>, required: true },
  currency: { type: Object as PropType<CurrencyModel>, required: true },
  disabled: { type: Boolean, default: true },
  precision: { type: [String, Number], required: true },
  showTotal: { type: Boolean, default: false },
  translationBaseKey: { type: String, required: true },
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const balanceMasked = inject('balanceMasked', ref(false));

const lockedBalance = computed(() => Number(props.balance.locked).toFixed(Number(props.precision)));
const hasLockedFunds = computed(() => props.balance.locked.gt(0));

const balanceValue = computed(() => {
  const available = new BigNumber(props.balance.available);

  if (props.showTotal) {
    return available.plus(props.balance.locked);
  }

  return available;
});
</script>

<template>
  <div class="wallet-input">
    <x-input-text
      class="wallet-input__input"
      :disabled="disabled"
      :variant="variant === 'light' ? 'light' : 'dark'"
      :value="$t(`${translationBaseKey}.value`, { currency: currency.symbol })"
      :label="$t(`${translationBaseKey}.label`)"
    >
      <template #right>
        <ot-balance
          :masked="balanceMasked"
          :precision="precision"
          :symbol="currency.symbol"
          :value="balanceValue"
        />
      </template>
    </x-input-text>
    <locked-funds
      v-if="hasLockedFunds"
      class="wallet-input__locked-funds"
      :currency="currency.symbol"
      :masked="balanceMasked"
      :precision="precision"
      :value="lockedBalance"
      :variant="variant"
    />
  </div>
</template>

<style lang="scss">
.wallet-input {
  display: flex;
  flex-direction: column;

  &__locked-funds {
    margin-top: 3px;
  }
}
</style>
