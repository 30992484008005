import { eotcService } from '@exchange/libs/eotc/service/src';
import { OrderSide } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import PublicRest from '@exchange/libs/rest-api/public-api';

import TradeModel from './trade-model';

export interface TradeHistoryFilter {
  from: Date;
  to: Date;
  instrumentCode?: string;
  cursor?: string;
}

class TradesService {
  // private readonly logPrefix = 'TradesService:';

  public fetchSpotHistory = async (payload: TradeHistoryFilter) => {
    const getQueryParams = () => ({
      from: payload.from.toISOString(),
      to: payload.to.toISOString(),
      ...(payload.instrumentCode && { instrument_code: payload.instrumentCode }),
      ...(payload.cursor && { cursor: payload.cursor }),
    });
    const r = await PublicRest.Account.Trades.get(getQueryParams());

    return {
      cursor: r.cursor,
      tradeHistory: r.trade_history.map((pair) => new TradeModel(pair)),
    };
  };

  public fetchEotcHistory = async (payload: { cursor?: string }) => {
    const getQueryParams = () => ({
      ...(payload.cursor && { cursor: payload.cursor }),
    });

    const { cursor, transactions } = await eotcService.fetchTrades(getQueryParams());
    const trades = transactions.map((t) => {
      const { base, quote } = t.traded_symbol;

      const fee = {
        collection_type: 'EOTC' as const,
        fee_amount: 'NaN', // no fees
        fee_currency: t.funding_currency.code === base.code ? quote.code : base.code,
      };
      const trade = {
        amount: t.amount,
        amount_received: t.details.cumAmt ?? 'NaN',
        instrument_code: t.funding_currency.code === base.code ? `${base.code}_${quote.code}` : `${quote.code}_${base.code}`,
        price: t.details.avgPx ?? 'NaN',
        side: t.funding_currency.code === base.code ? OrderSide.SELL : OrderSide.BUY,
        time: t.time,
        trade_id: t.transaction_id,
      };

      return new TradeModel({ fee, trade });
    });

    return { cursor, trades };
  };

  public fetchSpotTrades = async (orderId: string, cursor: string | null | undefined) => {
    const { trade_history: trades, cursor: incCursor } = await PublicRest.Account.Orders.getTrades(orderId, cursor);

    return {
      cursor: incCursor,
      trades,
    };
  };
}

const tradesService = new TradesService();

export default tradesService;
