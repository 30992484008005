import { nanoid } from 'nanoid';

import { logger } from '@exchange/libs/utils/simple-logger/src';

import { type HolderDetails } from './funding-account-holder';
import { type BEFundingTransferMethod, type BEFundingTransferMethodField } from './funding-transfer';
import { FundingTransferMethodType } from './funding-transfer';

/* eslint-disable camelcase */
interface FiatProviderDetails {
  fee_fix: string;
  fee_max: string;
  fee_percent: string;
  fiat_id: string;
  vip_fee_percent_difference: number;
  information_available: boolean;
}
interface MapFEPaymentOption {
  enabled: boolean;
  fiat_provider_details: [FiatProviderDetails];
  id: string;
  is_instant: boolean;
  name: string;
  short_name: string;
  unavailable: boolean;
}

export const mapFEPaymentOption = (item: BEFundingTransferMethod, fiatId: string): MapFEPaymentOption => ({
  fiat_provider_details: [
    {
      fee_fix: item.fee_fix,
      fee_max: item.fee_max,
      fee_percent: item.fee_percentage,
      fiat_id: fiatId,
      vip_fee_percent_difference: 0,
      information_available: false,
    },
  ],
  enabled: item.deposit_enabled,
  unavailable: false,
  id: item.transfer_method_type,
  short_name: item.name,
  name: item.name,
  is_instant: item.is_instant,
});
/* eslint-enable camelcase */

const mapAccountDetails = (fieldName: string, accountDetails: HolderDetails | undefined) => {
  if (!accountDetails) {
    return '';
  }

  if (fieldName === 'country_code') {
    return accountDetails.residence_country_code ?? '';
  }

  return accountDetails[fieldName] ?? '';
};

export class FundingProviderFieldModel {
  id!: string;

  alias!: string;

  fieldName!: string;

  fieldType!: string;

  isMandatory!: boolean;

  readOnly!: boolean;

  sort!: number;

  validation!: string;

  value: string;

  public getPayload(value: string) {
    return {
      field_name: this.fieldName,
      value,
    };
  }

  constructor(fields: BEFundingTransferMethodField, accountDetails: HolderDetails | undefined) {
    this.id = nanoid();
    this.alias = fields.field_name;
    this.fieldName = fields.field_name;
    this.fieldType = fields.field_type;
    this.isMandatory = fields.required;
    this.readOnly = fields.read_only;
    this.sort = fields.order;
    this.validation = fields.validation ?? '';
    this.value = mapAccountDetails(fields.field_name, accountDetails);
  }
}

export default class FundingProviderModel {
  id: FundingTransferMethodType;

  currencyCode: string;

  availableCurrencyCodes: Array<string>;

  depositEnabled: boolean;

  feeFix: string;

  processingFeeDeposit: string | undefined;

  processingFeeWithdrawal: string | undefined;

  fields: Array<FundingProviderFieldModel> = [];

  internalId = nanoid();

  isInstant: boolean;

  name: FundingTransferMethodType;

  shortName: string;

  slotName = 'external';

  title: FundingTransferMethodType;

  withdrawalEnabled: boolean;

  transferMethodType: FundingTransferMethodType;

  public isBlockchain = () => this.transferMethodType === FundingTransferMethodType.BLOCKCHAIN;

  public getPayload = (values: Dictionary<string>, type: FundingTransferMethodType) => {
    if ([FundingTransferMethodType.BLINC].includes(type)) {
      return {
        blinc_id: values.blinc_id,
        currency_code: values.currency_code,
        label: values.label,
        type,
      };
    }

    if ([FundingTransferMethodType.SEPA, FundingTransferMethodType.SWIFT].includes(type)) {
      return {
        account_holder_name: values.account_holder_name,
        address: values.address,
        bic: values.bic,
        country_code: values.country_code,
        currency_code: values.currency_code,
        iban: values.iban,
        label: values.label,
        type,
      };
    }

    if ([FundingTransferMethodType.SIGNET].includes(type)) {
      return {
        account_holder_name: values.account_holder_name,
        currency_code: values.currency_code,
        label: values.label,
        signet_address: values.signet_address,
        type,
      };
    }

    if ([FundingTransferMethodType.BLOCKCHAIN].includes(type)) {
      if (!values.destination_tag_type || values.destination_tag_type === '') {
        return {
          currency_code: values.currency_code,
          label: values.label,
          blockchain_address: values.blockchain_address,
          type,
        };
      }

      return {
        account_holder_name: values.account_holder_name,
        currency_code: values.currency_code,
        label: values.label,
        blockchain_address: values.blockchain_address,
        destination_tag: values.destination_tag,
        destination_tag_type: values.destination_tag_type,
        type,
      };
    }

    return {};
  };

  public addFields = (fields: Array<BEFundingTransferMethodField>, accountDetails: HolderDetails | undefined) => {
    if (!fields.length) {
      logger.error('Provider error: cannot add fields to model, fields are not provided');
      return;
    }

    this.fields = fields.map((f) => new FundingProviderFieldModel(f, accountDetails));
  };

  constructor(fields: BEFundingTransferMethod) {
    this.id = fields.transfer_method_type;

    this.transferMethodType = fields.transfer_method_type;

    this.shortName = fields.transfer_method_type.toLowerCase();

    this.title = fields.transfer_method_type;

    this.name = fields.transfer_method_type;

    this.isInstant = fields.is_instant;

    this.currencyCode = fields.currency_code;

    this.availableCurrencyCodes = [fields.currency_code];

    this.feeFix = fields.fee_fix;

    this.processingFeeDeposit = fields.processing_fee_deposit;

    this.processingFeeWithdrawal = fields.processing_fee_withdrawal;

    this.depositEnabled = fields.deposit_enabled;

    this.withdrawalEnabled = fields.withdrawal_enabled;
  }
}
