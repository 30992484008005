import { shallowRef } from 'vue';

import toastManagerInstance from './lib/toast-manager';
import OrderCancelFailedToastComponent from './lib/ui/orders/OrderCancelFailedToast.vue';
import OrderCancelledToastComponent from './lib/ui/orders/OrderCancelledToast.vue';
import OrderCreatedToastComponent from './lib/ui/orders/OrderCreatedToast.vue';
import OrderFilledToastFastComponent from './lib/ui/orders/OrderFilledFastToast.vue';
import OrderFilledToastComponent from './lib/ui/orders/OrderFilledToast.vue';
import OrderRejectedToastComponent from './lib/ui/orders/OrderRejectedToast.vue';
import StopOrderTriggeredToastComponent from './lib/ui/orders/StopOrderTriggeredToast.vue';
import SimpleToastComponent from './lib/ui/SimpleToast.vue';
import DepositConfirmedToastComponent from './lib/ui/transactions/DepositConfirmedToast.vue';

export const DepositConfirmedToast = shallowRef(DepositConfirmedToastComponent);
export const OrderCancelFailedToast = shallowRef(OrderCancelFailedToastComponent);
export const OrderCancelledToast = shallowRef(OrderCancelledToastComponent);
export const OrderCreatedToast = shallowRef(OrderCreatedToastComponent);
export const OrderFilledToast = shallowRef(OrderFilledToastComponent);
export const OrderFilledFastToast = shallowRef(OrderFilledToastFastComponent);
export const OrderRejectedToast = shallowRef(OrderRejectedToastComponent);
export const SimpleToast = shallowRef(SimpleToastComponent);
export const StopOrderTriggeredToast = shallowRef(StopOrderTriggeredToastComponent);

export { toastManagerInstance };
