<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { ModalContent, ModalFooter, modalVariant, type ModalVariant } from '@exchange/libs/modals/src';

import TransactionMessage from './TransactionMessage.vue';

const props = defineProps({
  operation: { type: String as PropType<'deposit' | 'withdraw'>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.light },
});
const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { t } = useI18n({ useScope: 'global' });

const title = computed(() => t('modules.transactions.subaccountTransfer.notMainSubaccount.title'));
const text = computed(() => t(`modules.transactions.subaccountTransfer.notMainSubaccount.text.${props.operation}`));
</script>

<template>
  <div class="account-is-not-main">
    <modal-content :variant="variant">
      <transaction-message
        :title="title"
        :text="text"
      />
    </modal-content>
    <modal-footer>
      <x-button
        variant="primary"
        :text="$t('fundamentals.close')"
        @click="emit('close')"
      />
    </modal-footer>
  </div>
</template>
