<script lang="ts" setup>
import SimpleToast from '../SimpleToast.vue';

const emit = defineEmits<{
  (e: 'remove'): void;
}>();
</script>

<template>
  <simple-toast
    icon-name="tick-circle-bulk"
    variant="success"
    title="modules.myOrders.toasts.created.title"
    message="modules.myOrders.toasts.created.message"
    @remove="emit('remove')"
  />
</template>
