import { FundingTransferMethodType } from './funding-transfer';
import { type BEAttributesField, type BEPayoutAccountModel } from './payout-account';

export class FundingAccountFieldModel {
  alias: string;

  fieldName: string;

  fieldType: string;

  sort: number;

  value: string | number;

  constructor(fields: BEAttributesField) {
    this.alias = fields.attributes.alias;
    this.fieldName = fields.attributes.field_name;
    this.fieldType = fields.attributes.field_type;
    this.sort = fields.attributes.sort;
    this.value = fields.attributes.value;
  }
}

export default class FundingAccountModel {
  public id: string;

  public copyValue?: number;

  public created: string;

  public currency: string;

  public fields: Array<FundingAccountFieldModel>;

  public payoutProviderId: FundingTransferMethodType;

  public state: string;

  constructor(fields: BEPayoutAccountModel) {
    this.id = fields.id;
    this.copyValue = fields.attributes.copy_value;
    this.created = fields.attributes.created;
    this.currency = fields.attributes.currency;
    this.fields = fields.attributes.fields.map((f) => new FundingAccountFieldModel(f)).sort((a, b) => a.sort - b.sort);
    this.payoutProviderId = fields.attributes.payout_provider_id;
    this.state = fields.attributes.state;
  }
}
