import { FundingTransferMethodType } from './funding-transfer';

interface BEPayoutAccountField {
  alias: string;
  field_name: string;
  field_type: string;
  sort: number;
  value: string | number;
}

export interface BEAttributesField {
  attributes: BEPayoutAccountField;
  type: 'payout_account_field';
}

export interface BEPayoutAccountAttributes {
  copy_value?: number;
  created: string;
  currency: string;
  deleted: boolean;
  editable: boolean;
  fields: Array<BEAttributesField>;
  payout_provider_id: FundingTransferMethodType;
  state: FundingPayoutAccountStatus;
  used: boolean;
}
export interface BEPayoutAccountModel {
  attributes: BEPayoutAccountAttributes;
  id: string;
  type: 'payout_account';
}

export enum FundingPayoutAccountStatus {
  CREATED = 'CREATED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}

export const DestinationTags = {
  memo: 'memo',
  message: 'message',
  destinationTag: 'destinationTag',
} as const;
export type DestinationTagType = (typeof DestinationTags)[keyof typeof DestinationTags];

export interface FundingPayoutAccountRequiredAttributes {
  payout_account_id: string;
  account_holder: string;
  currency: string;
  type: FundingTransferMethodType;
  state: FundingPayoutAccountStatus;
  created: string;
  label: string;
}
export interface FundingPayoutAccountSingleResponse extends FundingPayoutAccountRequiredAttributes {
  blinc_id: number;
  account_holder_name: string;
  iban: string;
  bic: string;
  address: string;
  country_code: string;
  signet_address: string;
  bank_name: string;
  bank_address: string;
  blockchain_address: string;
  destination_tag: string;
  destination_tag_type: DestinationTagType;
  account_number: string;
  routing_number: string;
}

export interface FundingPayoutAccountsResponse {
  payout_accounts: Array<FundingPayoutAccountSingleResponse>;
}

export const mapExchangeFundingResponse = (res: FundingPayoutAccountsResponse): Array<BEPayoutAccountModel> => {
  const getFields = (type: FundingTransferMethodType, account: FundingPayoutAccountSingleResponse): Array<BEAttributesField> => {
    if ([FundingTransferMethodType.BLINC].includes(type)) {
      return [
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Name',
            field_name: 'account_name',
            field_type: 'text',
            sort: 0,
            value: account.label,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'BLINC ID',
            field_name: 'blinc_id',
            field_type: 'text',
            sort: 1,
            value: account.blinc_id,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Currency',
            field_name: 'currency',
            field_type: 'text',
            sort: 2,
            value: account.currency,
          },
        },
      ];
    }

    if ([FundingTransferMethodType.SEPA, FundingTransferMethodType.SWIFT].includes(type)) {
      return [
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Name',
            field_name: 'account_name',
            field_type: 'text',
            sort: 0,
            value: account.label,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'IBAN',
            field_name: 'iban',
            field_type: 'text',
            sort: 1,
            value: account.iban,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Currency',
            field_name: 'currency',
            field_type: 'text',
            sort: 2,
            value: account.currency,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'BIC/SWIFT',
            field_name: 'bic',
            field_type: 'text',
            sort: 3,
            value: account.bic,
          },
        },
      ];
    }

    if ([FundingTransferMethodType.SIGNET].includes(type)) {
      return [
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Name',
            field_name: 'account_name',
            field_type: 'text',
            sort: 0,
            value: account.label,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Signet address',
            field_name: 'signet_address',
            field_type: 'text',
            sort: 1,
            value: account.signet_address,
          },
        },
        {
          type: 'payout_account_field',
          attributes: {
            alias: 'Currency',
            field_name: 'currency',
            field_type: 'text',
            sort: 2,
            value: account.currency,
          },
        },
      ];
    }

    if ([FundingTransferMethodType.BLOCKCHAIN].includes(type)) {
      const defaultsFields = [
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Name',
            field_name: 'account_name',
            field_type: 'text',
            sort: 0,
            value: account.label,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Blockchain address',
            field_name: 'blockchain_address',
            field_type: 'text',
            sort: 1,
            value: account.blockchain_address,
          },
        },
        {
          type: 'payout_account_field' as const,
          attributes: {
            alias: 'Currency',
            field_name: 'currency',
            field_type: 'text',
            sort: 2,
            value: account.currency,
          },
        },
      ];

      const destinationTag = {
        type: 'payout_account_field' as const,
        attributes: {
          alias: 'Destination tag',
          field_name: 'destination_tag',
          field_type: 'text',
          sort: 3,
          value: account.destination_tag,
        },
      };
      const destinationTagType = {
        type: 'payout_account_field' as const,
        attributes: {
          alias: 'Destination tag type',
          field_name: 'destination_tag_type',
          field_type: 'text',
          sort: 4,
          value: account.destination_tag_type,
        },
      };

      if (account.destination_tag) {
        defaultsFields.push(destinationTag);

        if (account.destination_tag_type) {
          defaultsFields.push(destinationTagType);
        }
      }

      return defaultsFields;
    }

    return [];
  };

  const mappedData = res.payout_accounts
    .map((account) => ({
      type: 'payout_account' as const,
      attributes: {
        copy_value: account.blinc_id,
        created: account.created,
        currency: account.currency,
        deleted: false,
        editable: false,
        fields: getFields(account.type, account),
        payout_provider_id: account.type,
        state: account.state,
        used: true,
      },
      id: account.payout_account_id,
    }))
    .sort((a) => (a.attributes.state === FundingPayoutAccountStatus.PENDING ? -1 : 1));

  return mappedData;
};
