<script lang="ts" setup>
import { computed, type PropType } from 'vue';

import type { Toast } from './toast-manager';

const props = defineProps({
  toast: {
    type: Object as PropType<Toast & { internalId: string }>,
    required: true,
  },
});
const emit = defineEmits<{
  (e: 'remove', value: { id: string; order: unknown }): void;
}>();

const order = computed(() => props.toast.order);
const duration = computed(() => props.toast.duration || 3000);
const toastId = computed(() => props.toast.internalId);

let activeTimeout: number | null = null;

const disableAutoRemove = () => {
  if (!activeTimeout) {
    return;
  }

  clearTimeout(activeTimeout);
  activeTimeout = null;
};

const remove = () => {
  disableAutoRemove();
  emit('remove', {
    id: toastId.value,
    order: order.value,
  });
};

const setupAutoRemove = () => {
  activeTimeout = window.setTimeout(remove, duration.value);
};

const onMouseEnter = () => {
  disableAutoRemove();
};

const onMouseLeave = () => {
  setupAutoRemove();
};

setupAutoRemove();
</script>

<template>
  <component
    :is="toast.content"
    :id="toastId"
    class="toast-wrapper"
    v-bind="toast.props"
    @remove="remove"
    @focusin="onMouseEnter"
    @focusout="onMouseLeave"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  />
</template>

<style lang="scss">
.toast-wrapper {
  z-index: 10;
  width: 100%;
  border: 0;
  border-radius: var(--app-border-radius);
  font-weight: var(--font-weight-medium);
}
</style>
