export type TCInstance = { addToast: (toast: Toast) => void };

export interface Toast {
  content: unknown;
  props?: Dictionary<unknown>;
  duration?: number;
  [key: string]: unknown;
}

class ToastManager {
  toastsContainer: TCInstance | undefined = undefined;

  public initialize = (rt: TCInstance) => {
    if (this.toastsContainer) {
      return;
    }

    this.toastsContainer = rt;
  };

  public addToast = (contents: Toast) => {
    this.toastsContainer?.addToast(contents);
  };
}

const toastManagerInstance = new ToastManager();

export default toastManagerInstance;
