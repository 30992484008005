<script lang="ts" setup>
import { type PropType } from 'vue';

import { type ModalVariant } from '@exchange/libs/modals/src';

defineProps({
  texts: { type: Array as PropType<Array<{ text: string; value: string; info?: string }>>, required: false },
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const getTooltip = (info: string) => ({
  content: info,
  theme: 'tooltip-coin-network',
  placement: 'right',
});
</script>

<template>
  <div
    class="transaction-fee-note"
    :class="[`transaction-fee-note--${variant}`]"
  >
    <div
      v-for="item in texts"
      :key="item.text"
      class="transaction-fee-note__item"
    >
      <div
        class="transaction-fee-note__label text-align-left"
        :class="{ 'transaction-fee-note__label--with-info': item.info }"
      >
        <div>{{ item.text }}</div>
        <ot-icon
          v-if="item.info"
          v-tooltip="getTooltip(item.info)"
          class="transaction-fee-note__info-icon"
          name="info-circle"
        />
      </div>
      <div
        v-if="item.value"
        class="transaction-fee-note__value text-align-right"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.transaction-fee-note {
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-medium);
  gap: 4px;

  &__item {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__label {
    display: flex;
  }

  &__info-icon {
    width: 16px;
    margin-left: 4px;
  }
}

.transaction-fee-note--dark {
  .transaction-fee-note {
    &__label {
      color: rgb(var(--v-theme-text-primary));
    }
  }
}
</style>
