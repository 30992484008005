<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';
import { maxValue, minValue, decimal, required } from '@vuelidate/validators';
import { computed, nextTick, onBeforeUnmount, reactive, ref, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import getBalance from '@exchange/helpers/balance';
import type BigNumber from '@exchange/helpers/bignumber';
import minimumIncrementForPrecision from '@exchange/helpers/minimum-increment-for-precision';
import useInputFocusFallback from '@exchange/libs/components/x-components/src/lib/useInputFocusFallback';
import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

const props = defineProps({
  currency: { type: Object as PropType<CurrencyModel>, required: true },
  delayTime: { type: Number, default: 500 },
  disabled: { type: Boolean, default: false },
  ignoreAmountTooSmallCheck: { type: Boolean, default: false },
  maxAmount: { type: [String, Number, Object] as PropType<string | number | BigNumber> },
  maxAmountDaily: { type: [String, Number] },
  minAmount: { type: [String, Number] },
  precision: { type: [String, Number], default: 2 },
  stopValidation: { type: Boolean, default: false },
  translationBaseKey: { type: String, default: '' },
  value: { type: String },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.light },
});
const emit = defineEmits<{
  (e: 'validation-change', v: boolean): void;
  (e: 'update:value', v: string | undefined): void;
}>();

const wrapperElement = ref<HTMLElement>();
const touchMap = new WeakMap();

const { t } = useI18n({ useScope: 'global' });
const { onFocusCb, onBlurCb } = useInputFocusFallback();

const maxAmountDailyWithPrecision = computed(() => getBalance({ v: props.maxAmountDaily ?? 0, precision: props.precision }));

const maxAmountWithPrecision = computed(() => getBalance({ v: props.maxAmount ?? 0, precision: props.precision }));

const minAmountWithPrecision = computed(() => {
  if (Number(props.minAmount) === 0) {
    return minimumIncrementForPrecision(props.precision);
  }

  return getBalance({ v: props.minAmount ?? 0, precision: props.precision });
});

const showMaxAmount = computed(() => {
  const tooSmall = !props.currency.checkAmountIsTooSmall(maxAmountWithPrecision.value);

  return props.maxAmount !== undefined && (props.ignoreAmountTooSmallCheck || tooSmall);
});

const validationRules = computed(() => ({
  amount: {
    decimal,
    required,
    ...(props.maxAmount !== undefined && {
      maxAmount: maxValue(maxAmountWithPrecision.value),
      maxAmountDaily(str) {
        if (!props.maxAmountDaily) {
          return true;
        }

        return Number(maxAmountDailyWithPrecision.value) > Number(str);
      },
    }),
    ...(props.minAmount !== undefined && { minAmount: minValue(minAmountWithPrecision.value) }),
  },
}));
const validationErrorMessages = computed(() => ({
  decimal: t('modules.transactions.amountInput.validations.decimal'),
  required: t('modules.transactions.amountInput.validations.required'),
  minAmount: t('modules.transactions.amountInput.validations.minAmount', { symbol: props.currency.symbol, minAmount: minAmountWithPrecision.value }),
  maxAmount: t('modules.transactions.amountInput.validations.maxAmount', { symbol: props.currency.symbol, maxAmount: maxAmountWithPrecision.value }),
  maxAmountDaily: t('modules.transactions.amountInput.validations.maxAmount', { symbol: props.currency.symbol, maxAmountDaily: maxAmountDailyWithPrecision.value }),
}));

const delayTouch = (v) => {
  v.$reset();

  if (touchMap.has(v)) {
    clearTimeout(touchMap.get(v));
  }

  touchMap.set(
    v,
    setTimeout(() => {
      v.$touch();
      nextTick(() => {
        emit('validation-change', v.$invalid);
      });
    }, props.delayTime),
  );
};

const amount = computed({
  get: () => props.value,
  set: (val) => {
    emit('update:value', val);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    delayTouch(v.value.amount);
  },
});

const v = useVuelidate(
  validationRules,
  reactive({
    amount,
  }),
);

const setMaxAmount = () => {
  amount.value = maxAmountWithPrecision.value;
};

const onFocus = () => {
  onFocusCb(wrapperElement.value);
};
const onBlur = () => {
  onBlurCb();
};

onBeforeUnmount(() => {
  if (touchMap.has(v.value.amount)) {
    clearTimeout(touchMap.get(v.value.amount));
  }
});
</script>

<template>
  <ot-input-error-wrapper
    ref="wrapperElement"
    class="amount-input"
    :error="v.amount"
    :error-messages="validationErrorMessages"
    :disabled="stopValidation"
  >
    <x-input-number
      v-model:value="amount"
      name="amount-input"
      label-class="amount-input__label"
      currency-display="code"
      :variant="variant"
      :label="$t('modules.transactions.amount')"
      :state="v.amount.$error ? 'error' : 'default'"
      :precision="Number(precision)"
      :currency="currency.symbol"
      :disabled="disabled"
      @inner-right-click="setMaxAmount"
      @focus="onFocus"
      @blur="onBlur"
    >
      <template #right>
        <x-button
          v-if="showMaxAmount"
          variant="tertiary"
          size="xs"
          >{{ $t('fundamentals.max') }}</x-button
        >
      </template>
    </x-input-number>
  </ot-input-error-wrapper>
</template>
