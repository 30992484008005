<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { ModalContent, ModalFooter, modalVariant, type ModalVariant } from '@exchange/libs/modals/src';
import { type VerificationFlag } from '@exchange/libs/utils/launchdarkly/src';

const props = defineProps({
  currencySymbol: { type: String },
  linkTextKey: { type: String, required: true },
  textKey: { type: String, required: true },
  verification: { type: Object as PropType<VerificationFlag>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.light },
});

const { t } = useI18n({ useScope: 'global' });

const text = computed(() => {
  if (props.currencySymbol) {
    return t(props.textKey, { currency: props.currencySymbol });
  }

  return t(props.textKey);
});
const buttonText = computed(() => t(props.linkTextKey));
</script>

<template>
  <div class="user-not-verified">
    <modal-content :variant="variant">
      <h4 class="user-not-verified__title">{{ $t('modules.transactions.verification.title') }}</h4>
      <p>{{ text }}</p>
    </modal-content>
    <modal-footer>
      <x-button
        v-if="verification.ready"
        class="user-not-verified__verify"
        variant="primary"
        size="sm"
        :text="buttonText"
        :href="verification.link"
        testid="user-verify"
      />
    </modal-footer>
  </div>
</template>
