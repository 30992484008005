export enum FundingTransferMethodType {
  BLINC = 'BLINC',
  BLOCKCHAIN = 'BLOCKCHAIN',
  CARD = 'CARD',
  INSTANT_TRADE_ACCOUNT_TRANSFER = 'INSTANT_TRADE_ACCOUNT_TRANSFER',
  INTERNAL = 'INTERNAL',
  SEPA = 'SEPA',
  SIGNET = 'SIGNET',
  SUBACCOUNT_TRANSFER = 'SUBACCOUNT_TRANSFER',
  SWIFT = 'SWIFT',
  WIRE = 'WIRE',
}

export enum FundingTransferStatus {
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  DONE = 'DONE',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PENDING = 'PENDING',
  CARD_PENDING = 'CARD_PENDING',
  REJECTED = 'REJECTED',
  UNDER_REVIEW = 'UNDER_REVIEW',
}

/* eslint-disable camelcase */
export interface BEFundingTransferMethod {
  currency_code: string;
  deposit_enabled: boolean;
  fee_fix: string;
  fee_max: string;
  fee_percentage: string;
  processing_fee_deposit: string | undefined;
  processing_fee_withdrawal: string | undefined;
  has_terms: boolean;
  is_instant: boolean;
  name: string;
  transfer_method_type: FundingTransferMethodType;
  withdrawal_enabled: boolean;
}

export interface BEFundingTransferMethods {
  transfer_methods: Array<BEFundingTransferMethod>;
}

export interface BEFundingTransferMethodField {
  field_name: string;
  field_type: string;
  optional: boolean;
  order: number;
  read_only: boolean;
  required: boolean;
  validation: string;
}
export interface BEFundingTransferMethodFields {
  transfer_method_type: FundingTransferMethodType;
  fields: Array<BEFundingTransferMethodField>;
}
/* eslint-enable camelcase */
