import { CurrencyModel } from '@exchange/libs/utils/currency/src';

import { FundingTransferStatus, FundingTransferMethodType } from './funding-transfer';

/* eslint-disable camelcase */

export enum FundingTransferDirection {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export interface BETransaction {
  account_holder_id: string;
  account_id: string;
  amount: string;
  bic?: string;
  blockchain_transaction_id?: string;
  completed_at: string;
  currency: string;
  destination_account: string;
  destination_blinc_id?: number;
  direction: FundingTransferDirection;
  fee_amount: string;
  iban?: string;
  net_amount: string;
  payout_account_label?: string;
  source_account: string;
  transaction_id: string;
  transfer_method_type: FundingTransferMethodType;
  transfer_status: FundingTransferStatus;
  user_confirmed_at: string;
  user_requested_at: string;
}
/* eslint-enable camelcase */

export enum FETransactionType {
  subaccountTransfer = 'subaccountTransfer',
  depositInternal = 'depositInternal',
  withdrawalInternal = 'withdrawalInternal',
  depositInstant = 'depositInstant',
  withdrawalInstant = 'withdrawalInstant',
  depositCryptoExternal = 'depositCryptoExternal',
  depositFiatExternal = 'depositFiatExternal',
  withdrawalCryptoExternal = 'withdrawalCryptoExternal',
  withdrawalFiatExternal = 'withdrawalFiatExternal',
}

export default class TransactionModel {
  public amount: string;

  public bic?: string;

  public blockchainTransactionId?: string;

  public completedAt?: Date;

  public confirmed: boolean;

  public currency: string;

  public destinationAccount: string;

  public destinationBlincId?: number;

  public direction: FundingTransferDirection;

  public fee: string;

  public id: string;

  public iban?: string;

  public lastChanged: Date;

  public netAmount: string;

  public payoutAccountLabel?: string;

  public sourceAccount: string;

  public status: FundingTransferStatus;

  public time: Date;

  public transactionType: FETransactionType;

  public transferMethodType: FundingTransferMethodType;

  public get waitingForEmail() {
    return this.confirmed === false && this.status !== FundingTransferStatus.CANCELLED;
  }

  public get isInstantSubaccountTransaction() {
    return [FETransactionType.depositInstant, FETransactionType.withdrawalInstant].includes(this.transactionType);
  }

  constructor(fields: BETransaction, accountInfo: { mainAccountId?: string; isInstantAccountRequestor: boolean }) {
    this.amount = fields.amount;
    this.bic = fields.bic;
    this.blockchainTransactionId = fields.blockchain_transaction_id;
    this.completedAt = fields.completed_at ? new Date(fields.completed_at) : undefined;
    this.confirmed = fields.transfer_status !== FundingTransferStatus.INITIATED;
    this.currency = fields.currency;
    this.destinationAccount = fields.destination_account;
    this.destinationBlincId = fields.destination_blinc_id;
    this.direction = this.getDirection(
      {
        type: fields.transfer_method_type,
        direction: fields.direction,
        source: fields.source_account,
        destination: fields.destination_account,
      },
      accountInfo,
    );
    this.fee = fields.fee_amount;
    this.iban = fields.iban;
    this.id = fields.transaction_id;
    this.lastChanged = new Date(fields.user_requested_at);
    this.netAmount = fields.net_amount;
    this.payoutAccountLabel = fields.payout_account_label;
    this.sourceAccount = fields.source_account;
    this.status = fields.transfer_status;
    this.time = new Date(fields.user_requested_at);
    this.transferMethodType = fields.transfer_method_type;
    this.transactionType = this.getTransactionType();
  }

  private getDirection = (
    data: {
      type: FundingTransferMethodType;
      direction: FundingTransferDirection;
      source: string;
      destination: string;
    },
    accountInfo: { mainAccountId?: string; isInstantAccountRequestor: boolean },
  ) => {
    if (data.type !== FundingTransferMethodType.INSTANT_TRADE_ACCOUNT_TRANSFER) {
      return data.direction;
    }

    if (!accountInfo.mainAccountId) {
      return data.direction;
    }

    if (accountInfo.isInstantAccountRequestor) {
      if (accountInfo.mainAccountId === data.source) {
        return FundingTransferDirection.INCOMING;
      }

      return FundingTransferDirection.OUTGOING;
    }

    if (accountInfo.mainAccountId === data.source) {
      return FundingTransferDirection.OUTGOING;
    }

    return FundingTransferDirection.INCOMING;
  };

  private getTransactionType = () => {
    const isFiat = CurrencyModel.isFiatCurrency(this.currency);

    if (this.transferMethodType === FundingTransferMethodType.SUBACCOUNT_TRANSFER) {
      return FETransactionType.subaccountTransfer;
    }

    if (this.transferMethodType === FundingTransferMethodType.INSTANT_TRADE_ACCOUNT_TRANSFER) {
      if (this.direction === FundingTransferDirection.INCOMING) {
        return FETransactionType.depositInstant;
      }

      return FETransactionType.withdrawalInstant;
    }

    if (this.transferMethodType === FundingTransferMethodType.INTERNAL) {
      if (this.direction === FundingTransferDirection.INCOMING) {
        return FETransactionType.depositInternal;
      }

      return FETransactionType.withdrawalInternal;
    }

    if (this.direction === FundingTransferDirection.INCOMING) {
      if (isFiat) {
        return FETransactionType.depositFiatExternal;
      }

      return FETransactionType.depositCryptoExternal;
    }

    if (isFiat) {
      return FETransactionType.withdrawalFiatExternal;
    }

    return FETransactionType.withdrawalCryptoExternal;
  };

  public updateStatus = (newStatus: FundingTransferStatus) => {
    this.status = newStatus;
  };
}
