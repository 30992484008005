<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import usePromise from '@exchange/libs/composables/shared/src/lib/usePromise';
import { modalVariant } from '@exchange/libs/modals/src';
import { subaccountsService } from '@exchange/libs/trading-accounts/service/src';
import { useInitTransaction } from '@exchange/libs/transactions/funds/service/src';
import { CoinSelect } from '@exchange/libs/transactions/shared/src';
import TransactionModal from '@exchange/libs/transactions/shared/src/lib/TransactionModal.vue';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

import TransferSubaccount from './TransferPaymentMethod.vue';

/**
 * To open modal, navigate to route with 'Transfer' hash;
 * To preselect currency - add 'currency' query param with the value of desired currency code
 * To preselect source account - add 'source' query param with the value of desired account name. If not provided "Main" will be used
 * To preselect destination account - add 'destination' query param with the value of desired account name
 *
 * Example router.push({ hash: '#Transfer', query: { currency: 'BTC', destination: 'kartoffel' } });
 */

const router = useRouter();
const route = useRoute();

const { listWithInstantTrade, fetchList: fetchSubaccounts, getAccountByProp } = subaccountsService;

const variant = modalVariant.dark;
const preselectedFrom = ref<string | undefined>();
const preselectedTo = ref<string | undefined>();

const getAccountByName = getAccountByProp('name');

const {
  currenciesToShow,
  defaultFiatCurrency,
  filterCurrencyList,
  getAndRemoveParamsFromRouteQuery,
  resetSelectedCurrency,
  selectedCurrency,
  waitForCurrenciesWithTimeLimitAndSetSelectedOne,
  search,
} = useInitTransaction({ withAutomaticSwitchToMain: false });

const getPreselectedValues = async () => {
  const [source, destination, currency] = await getAndRemoveParamsFromRouteQuery(route, router, ['source', 'destination', 'currency']);

  waitForCurrenciesWithTimeLimitAndSetSelectedOne(currency);

  if (typeof destination === 'string') {
    preselectedTo.value = getAccountByName(destination)?.name;
  }

  let sourceNameToUse = CONSTANTS.MAIN_SUBACCOUNT_NAME;

  if (typeof source === 'string' && preselectedTo.value !== CONSTANTS.INSTANT_TRADE_ACCOUNT_NAME) {
    sourceNameToUse = source;
  }

  preselectedFrom.value = getAccountByName(sourceNameToUse)?.name;
};

const { loading: loadingSubaccounts, createPromise: onCreated } = usePromise(async () => {
  await fetchSubaccounts();
  await getPreselectedValues();
});

onCreated();
</script>

<template>
  <transaction-modal
    class="transfer-modal"
    name="transfer"
    :variant="variant"
    :title="$t('modules.transactions.subaccountTransfer.transfer')"
  >
    <template #content="{ searchClass, coinSelectClass, contentClass }">
      <div
        class="transfer-modal__search"
        :class="searchClass"
      >
        <ot-search
          v-model:value="search"
          :variant="variant"
          :full-width="true"
          :on-search-cb="filterCurrencyList"
          @input-click="resetSelectedCurrency"
        />
      </div>
      <coin-select
        v-model:selectedCurrency="selectedCurrency"
        type="transfer"
        :variant="variant"
        :class="coinSelectClass"
        :currencies="currenciesToShow"
        :default-fiat-currency="defaultFiatCurrency"
        :balances="undefined"
      />
      <template v-if="selectedCurrency">
        <ot-loading-indicator :until="loadingSubaccounts">
          <transfer-subaccount
            :key="selectedCurrency.id"
            :class="contentClass"
            :variant="variant"
            :accounts="listWithInstantTrade"
            :currency="selectedCurrency"
            :should-disable-select="false"
            :preselected-from="preselectedFrom"
            :preselected-to="preselectedTo"
          />
        </ot-loading-indicator>
      </template>
    </template>
  </transaction-modal>
</template>
