<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { type ModalVariant } from '@exchange/libs/modals/src';
import { type FundingProviderModel } from '@exchange/libs/transactions/funds/service/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

const props = defineProps({
  blockchainFee: { type: String, required: true },
  currency: { type: Object as PropType<CurrencyModel>, required: true },
  currencyNetwork: { type: String, required: false },
  isBlockchain: { type: Boolean, default: false },
  isInstant: { type: Boolean, default: false },
  isListItem: { type: Boolean, default: false },
  paymentOption: { type: Object as PropType<FundingProviderModel>, required: true },
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const { t } = useI18n({ useScope: 'global' });

const name = computed(() => t(`modules.funds.api.transferMethods.${props.paymentOption.title}`));
const daysToTransfer = computed(() => (props.isBlockchain ? undefined : t(`modules.transactions.paymentOptions.${props.isInstant ? 'instant' : 'notInstant'}`)));
const nameToDisplay = computed(() => {
  const days = daysToTransfer.value ? `(${daysToTransfer.value})` : '';

  return `${name.value} ${days}`;
});
</script>

<template>
  <div
    class="payment-select-option"
    testid="payment-select-option"
    :class="[`payment-select-option--${variant}`, { 'payment-select-option--list-item': isListItem }]"
  >
    <div class="payment-select-option__info">
      <div class="payment-select-option__name">
        {{ nameToDisplay }}
      </div>
    </div>
    <div v-if="isBlockchain && currencyNetwork">
      {{ currencyNetwork }}
    </div>
  </div>
</template>

<style lang="scss">
.payment-select-option {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: var(--app-line-height-smaller);

  &__info {
    display: flex;
    min-height: 0;
    flex: 1 0 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  &__name {
    overflow: hidden;
    max-width: 95%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.payment-select-option--list-item {
  padding: 6px;
}
</style>
