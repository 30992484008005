<script lang="ts" setup>
import { computed, defineAsyncComponent, shallowRef, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { modalService } from '@exchange/libs/modals/src';
import { type ModalVariant } from '@exchange/libs/modals/src';
import { subaccountsService, type Subaccount } from '@exchange/libs/trading-accounts/service/src';
import { PaymentSingle } from '@exchange/libs/transactions/shared/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

import SubaccountTransferSuccess from './SubaccountTransferSuccess.vue';
import TransferForm, { type TransferFundsCall } from './TransferForm.vue';

const ErrorModal = defineAsyncComponent(() => import('@exchange/libs/modals/src/lib/common/ErrorModal.vue'));

const props = defineProps({
  accounts: { type: Array as PropType<Array<Subaccount>>, required: true },
  currency: { type: Object as PropType<CurrencyModel | { symbol: string }>, required: true },
  preselectedFrom: { type: String, required: false },
  preselectedTo: { type: String, required: false },
  shouldDisableSelect: { type: Boolean, required: true },
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const { t } = useI18n({ useScope: 'global' });

const { transfer } = subaccountsService;

const paymentMethod = computed(() => ({
  label: t('modules.transactions.paymentOptions.depositMethod'),
  value: t('modules.transactions.subaccountTransfer.transferMethodName'),
}));

const transferFunds: TransferFundsCall = async (data) => {
  try {
    await transfer({
      amount: data.amount,
      currencySymbol: data.currencySymbol,
      source: data.sourceId,
      sourceType: data.sourceType,
      destination: data.destinationId,
      destinationType: data.destinationType,
    });

    modalService.substitute(
      shallowRef(SubaccountTransferSuccess),
      {
        variant: props.variant,
        amount: data.amount,
        currency: data.currencySymbol,
        symbol: data.currencySymbol,
        subaccountFrom: data.sourceName,
        subaccountTo: data.destinationName,
      },
      {},
      { variant: props.variant },
    );

    return {
      error: undefined,
    };
  } catch (error) {
    const e = error as { status: number; error?: string; data?: { error?: string } };

    if (e.status === 422) {
      let transferErrorMessage: string;

      if (e.data?.error === 'INSUFFICIENT_FUNDS') {
        transferErrorMessage = t('fundamentals.INSUFFICIENT_FUNDS');
      } else {
        transferErrorMessage = t('fundamentals.defaultError');
      }

      return {
        error: transferErrorMessage,
      };
    }

    modalService.substitute(
      shallowRef(ErrorModal),
      {
        variant: props.variant,
        titleKey: 'fundamentals.unavailableError.title',
        messageKey: 'fundamentals.unavailableError.text',
      },
      {
        'x-error-close': () => {
          modalService.clear();
        },
      },
    );

    return {
      error,
    };
  }
};

const onTransferCancel = () => modalService.clear();
</script>

<template>
  <div class="transfer-payment-method">
    <payment-single
      class="transfer-payment-method__name"
      :method="paymentMethod"
    />
    <transfer-form
      class="transfer-payment-method__form"
      :currency="currency"
      :accounts="accounts"
      :preselected-from="preselectedFrom"
      :preselected-to="preselectedTo"
      :variant="variant"
      :should-disable-select="shouldDisableSelect"
      :transfer="transferFunds"
      @cancel="onTransferCancel"
    />
  </div>
</template>

<style lang="scss">
.transfer-payment-method {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__name {
    padding: var(--m-content-padding-y) var(--m-content-padding-x);
  }

  &__form {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
</style>
