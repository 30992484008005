<script lang="ts" setup>
import { computed, ref, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import getBalance from '@exchange/helpers/balance';
import BigNumber from '@exchange/helpers/bignumber';
import { balanceService, BalanceModel } from '@exchange/libs/balances/service/src';
import { type ModalVariant } from '@exchange/libs/modals/src';
import { type FundingProviderModel } from '@exchange/libs/transactions/funds/service/src';
import { type CurrencyModel } from '@exchange/libs/utils/currency/src';

import PaymentSelectOption from './PaymentSelectOption.vue';
import PaymentSingle, { type PaymentSingleItem } from './PaymentSingle.vue';

export type Action = 'deposit' | 'withdraw';

const props = defineProps({
  action: { type: String as PropType<Action>, required: true },
  currency: { type: Object as PropType<CurrencyModel>, required: true },
  currencyNetwork: { type: String, required: false },
  currentPayment: { type: Object as PropType<FundingProviderModel>, required: false },
  disabled: { type: Boolean, default: false },
  labelText: { type: String, required: true },
  payments: { type: Array as PropType<Array<FundingProviderModel>>, required: true },
  placeholderText: { type: String, required: true },
  variant: { type: String as PropType<ModalVariant>, required: true },
});
const emit = defineEmits<{
  (e: 'update:currentPayment', v: FundingProviderModel): void;
}>();

const { t } = useI18n({ useScope: 'global' });

const { balances } = balanceService;

const firstSelectDone = ref(false);

const getBlockchainFee = (method: FundingProviderModel) => (props.action === 'deposit' ? '0' : new BigNumber(method.feeFix).toFixed(props.currency.transactionPrecision));

const getPaymentMethodInfo = (method: FundingProviderModel) => {
  const isBlockchain = method.isBlockchain();
  const { isInstant } = method;
  const blockchainFee = getBlockchainFee(method);

  return {
    isBlockchain,
    isInstant,
    blockchainFee,
  };
};

const getMethodInfo = (method: FundingProviderModel) => {
  const name = t(`modules.funds.api.transferMethods.${method.title}`);
  const network = {
    label: t('modules.transactions.paymentOptions.network.title'),
    infoMessage: t('modules.transactions.paymentOptions.network.infoMessage'),
  };
  const { isBlockchain, isInstant } = getPaymentMethodInfo(method);

  if (isBlockchain) {
    const methodInfo: PaymentSingleItem = {
      label: props.labelText,
      value: name,
    };
    const networkInfo: PaymentSingleItem | undefined = props.currencyNetwork
      ? {
          label: network.label,
          value: props.currencyNetwork,
          info: {
            message: network.infoMessage,
          },
        }
      : undefined;

    return {
      method: methodInfo,
      network: networkInfo,
    };
  }

  const daysToTransfer = t(`modules.transactions.paymentOptions.${isInstant ? 'instant' : 'notInstant'}`);

  const methodInfo = {
    label: props.labelText,
    value: `${name}\n${daysToTransfer}`,
  };

  return {
    method: methodInfo,
    network: undefined,
  };
};

const singlePaymentMethod = computed(() => {
  const [method] = props.payments;

  if (props.payments.length === 1 && method) {
    return getMethodInfo(method);
  }

  return undefined;
});
const currentPaymentMethodInfo = computed(() => (props.currentPayment ? getMethodInfo(props.currentPayment) : undefined));

const balance = computed(() => {
  const cc = props.currency.symbol;

  return (
    balances.value[cc] ||
    new BalanceModel({
      locked: '0',
      available: '0',
      currency_code: props.currency.symbol,
      sequence: -1,
    })
  );
});

const balanceAvailableWithPrecision = computed(() => getBalance({ v: new BigNumber(balance.value.available), precision: props.currency.transactionPrecision }));

const activate = (payment: FundingProviderModel) => {
  emit('update:currentPayment', payment);
  firstSelectDone.value = true;
};
</script>

<template>
  <div class="payment-select">
    <payment-single
      v-if="singlePaymentMethod"
      class="payment-select__block"
      :method="singlePaymentMethod.method"
      :network="singlePaymentMethod.network"
    />
    <div
      v-else
      class="payment-select__block"
    >
      <div class="payment-select__label">{{ labelText }}</div>
      <ot-select
        class="payment-select__input"
        :class="{ 'payment-select__input--placeholder': !currentPayment }"
        :data-placeholder="placeholderText"
        :menu-top-space="false"
        :variant="variant === 'dark' ? 'dark-outlined' : 'light-outlined'"
        label="slotName"
        :options="payments"
        :disabled="false"
        :value="currentPayment"
        testid="payment-select"
        @update:value="activate"
      >
        <template #selected-option="selected: FundingProviderModel">
          <payment-select-option
            v-if="selected"
            :blockchain-fee="getBlockchainFee(selected)"
            :currency="currency"
            :currency-network="currencyNetwork"
            :is-blockchain="selected.isBlockchain()"
            :is-instant="selected.isInstant"
            :payment-option="selected"
            :variant="variant"
          />
        </template>
        <template #option="option: FundingProviderModel">
          <payment-select-option
            :blockchain-fee="getBlockchainFee(option)"
            :currency="currency"
            :currency-network="currencyNetwork"
            :is-blockchain="option.isBlockchain()"
            :is-instant="option.isInstant"
            :is-list-item="true"
            :payment-option="option"
            :variant="variant"
          />
        </template>
      </ot-select>

      <payment-single
        v-if="currentPaymentMethodInfo"
        class="payment-select__current-payment-block"
        :method="currentPaymentMethodInfo.method"
        :network="currentPaymentMethodInfo.network"
      />
    </div>
    <div
      v-if="$slots['error'] && !firstSelectDone"
      class="payment-select__block"
    >
      <slot name="error" />
    </div>
    <ot-transition-fade>
      <div
        v-if="$slots['info']"
        class="payment-select__block"
      >
        <slot name="info" />
      </div>
    </ot-transition-fade>
    <ot-transition-fade>
      <slot
        v-if="currentPayment"
        :name="currentPayment.slotName"
        :payment-content-class="'payment-select__content'"
        :current-payment="currentPayment"
        :balance="balance"
        :balance-available-with-precision="balanceAvailableWithPrecision"
      />
    </ot-transition-fade>
  </div>
</template>

<style lang="scss">
.payment-select {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__block {
    padding: var(--m-content-padding-y) var(--m-content-padding-x);
  }

  &__current-payment-block {
    padding: var(--m-content-padding-y) 0;
  }

  &__input--placeholder {
    position: relative;
    min-height: 34.5px;

    &::before {
      position: absolute;
      top: 50%;
      content: attr(data-placeholder);
      font-weight: var(--font-weight-regular);
      transform: translate(10px, -50%);
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
</style>
