<script lang="ts">
export default {
  name: 'PaymentSingle',
  customOptions: {},
};

export type PaymentSingleItem = {
  label: string;
  value: string;
  info?: {
    message: string;
  };
};
</script>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';

const props = defineProps({
  method: { type: Object as PropType<PaymentSingleItem>, required: true },
  network: { type: Object as PropType<PaymentSingleItem | undefined>, required: false },
  networkFee: { type: Object as PropType<PaymentSingleItem | undefined>, required: false },
});

const itemsToShow = computed(() => {
  const a = [props.method];

  if (props.network) {
    a.push(props.network);
  }

  if (props.networkFee) {
    a.push(props.networkFee);
  }

  return a;
});

const getTooltip = (d: { message: string }) => ({
  content: d.message,
  theme: 'tooltip-coin-network',
  placement: 'right',
});
</script>

<template>
  <div class="payment-single">
    <div
      v-for="item in itemsToShow"
      :key="item.label"
      class="payment-single__label"
    >
      <div class="payment-single__title">
        <span>{{ item.label }}</span>
        <ot-icon
          v-if="item.info"
          v-tooltip="getTooltip(item.info)"
          class="payment-single__info-icon"
          name="info-circle"
        />
      </div>
      <div class="payment-single__method-name">{{ item.value }}</div>
    </div>
  </div>
</template>

<style lang="scss">
.payment-single {
  &__label {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__info-icon {
    width: 16px;
    margin-left: 4px;
  }

  &__method-name {
    color: var(--modal-content-default-color);
    text-align: right;
    white-space: pre-line;
  }
}
</style>
