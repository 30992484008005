<script lang="ts" setup>
import SimpleToast from '../SimpleToast.vue';
import SimpleToastMessage from '../SimpleToastMessage.vue';

defineProps({
  amount: { type: String, required: true },
  precision: { type: Number, required: true },
  currencyCode: { type: String, required: true },
});
const emit = defineEmits<{
  (e: 'remove'): void;
}>();
</script>

<template>
  <simple-toast
    class="deposit-confirmed-toast"
    variant="success"
    icon-name="tick-circle-bulk"
    title="modules.transactions.deposit.toasts.confirmed.title"
    @remove="emit('remove')"
  >
    <template #message>
      <simple-toast-message>
        <div class="deposit-confirmed-toast__amount">{{ $t('modules.transactions.deposit.toasts.confirmed.message') }}</div>
        <ot-number-display
          class="deposit-confirmed-toast__number"
          :number="amount"
          :precision="precision"
        />
        <div class="deposit-confirmed-toast__currency">{{ currencyCode }}</div>
      </simple-toast-message>
    </template>
  </simple-toast>
</template>
