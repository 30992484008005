<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import SimpleToast from '../SimpleToast.vue';

const props = defineProps({
  errorCode: { type: String, required: false },
});
const emit = defineEmits<{
  (e: 'remove'): void;
}>();

const { te } = useI18n({ useScope: 'global' });

const reason = computed(() => {
  const tKey = `modules.myOrders.toasts.cancelled.reasons.${props.errorCode}`;

  return te(tKey) ? tKey : 'modules.myOrders.toasts.cancelled.failed';
});
</script>

<template>
  <simple-toast
    variant="failed"
    icon-name="danger"
    title="fundamentals.toasts.failed"
    :message="reason"
    @remove="emit('remove')"
  />
</template>
