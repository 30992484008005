/* eslint-disable camelcase */
export interface BESubaccountLimits {
  account_holder: string;
  max_active_accounts: number;
  max_closed_accounts: number;
}
/* eslint-enable camelcase */

export default class SubaccountLimits {
  public id!: string;

  public maxActive!: number;

  public maxClosed!: number;

  constructor(fields: BESubaccountLimits) {
    /** we need to add "1" because "main" is not counted in back-end */
    this.maxActive = fields.max_active_accounts + 1;
    this.maxClosed = fields.max_closed_accounts;
  }
}
