<template>
  <div class="simple-toast-message">
    <slot />
  </div>
</template>

<style lang="scss">
.simple-toast-message {
  display: grid;
  grid-gap: 7px;
  grid-template-columns: auto min-content 36px;
}
</style>
