import { uniqBy } from 'lodash/fp';

import WebRest from '@exchange/libs/rest-api/web-api';
import { CurrencyModel } from '@exchange/libs/utils/currency/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { type HolderDetails } from './models/funding-account-holder';
import FundingAccountModel from './models/funding-account-model';
import { type BEFiatDepositDetails, type BEBlockchainDepositDetails } from './models/funding-deposit';
import FundingProviderModel from './models/funding-provider-model';
import { type BEFundingTransferMethod, FundingTransferMethodType } from './models/funding-transfer';
import { mapExchangeFundingResponse } from './models/payout-account';
import TransactionModel from './models/transactions-model';

interface FundsAPIAccountDetailError {
  status: number;
}
export interface TransferMethodOption {
  id: FundingTransferMethodType;
  name: string;
  isFiat: boolean;
}

class FundsService {
  private readonly logPrefix = 'FundsService:';

  public accountHolderDetails: HolderDetails | undefined;

  private fetchFundingProviderFields = async (providers: Array<FundingProviderModel>): Promise<FundingProviderModel[]> => {
    const providersMap: Array<FundingProviderModel> = [];

    try {
      const accountDetails = await this.accountHolder.get().catch(() => undefined);

      // eslint-disable-next-line no-restricted-syntax
      for await (const provider of providers) {
        const res = await this.transferMethods.getFields(provider.id);

        provider.addFields(res.fields, accountDetails);
        providersMap.push(provider);
      }
    } catch (e) {
      logger.error('Failed to fetch fields for funding providers:\t', e);
      throw e;
    }

    return providersMap;
  };

  public accountHolder = {
    get: async () => {
      try {
        const accountDetails = await WebRest.Funding.AccountHolder.Details.get();

        this.accountHolderDetails = accountDetails;

        return accountDetails;
      } catch (e) {
        if ((e as FundsAPIAccountDetailError).status === 404) {
          return undefined;
        }

        logger.error('Failed to fetch account-details for user:\t', e);

        throw e;
      }
    },
  };

  public payoutAccounts = {
    get: async (transferMethodType?: FundingTransferMethodType) => {
      try {
        const res = await WebRest.Funding.PayoutAccounts.get(transferMethodType);

        const mappedAccounts: Array<FundingAccountModel> = mapExchangeFundingResponse(res).map((e) => new FundingAccountModel(e));

        return mappedAccounts;
      } catch (e) {
        logger.error(`${this.logPrefix} Failed to fetch funding accounts:\t`, e);
        throw e;
      }
    },
    create: async ({
      provider,
      values,
      fetchAll,
      fetchTransferMethodType,
    }: {
      provider: FundingProviderModel;
      values: Dictionary<string>;
      fetchAll: boolean;
      fetchTransferMethodType?: FundingTransferMethodType;
    }) => {
      try {
        const res = await WebRest.Funding.PayoutAccounts.create(provider.getPayload(values, provider.id));

        const fundingAccounts = await this.payoutAccounts.get(fetchAll ? undefined : fetchTransferMethodType || provider.id);

        return fundingAccounts.find((acc) => acc.id === res.payout_account_id);
      } catch (e) {
        logger.error(`${this.logPrefix} Failed to create payout account:\t`, e);
        throw e;
      }
    },
    delete: async (id: string, transferMethodType?: FundingTransferMethodType) => {
      try {
        await WebRest.Funding.PayoutAccounts.delete(id);

        return await this.payoutAccounts.get(transferMethodType);
      } catch (e) {
        logger.error(`${this.logPrefix} Failed to delete funding account #${id}:\t`, e);
        throw e;
      }
    },
  };

  public transferMethods = {
    getAll: async (): Promise<{ uniqMethods: Array<TransferMethodOption>; allMethods: Array<BEFundingTransferMethod> }> => {
      try {
        const res = await WebRest.Funding.TransferMethods.getAll();

        const uniqMethods = uniqBy('transfer_method_type', res.transfer_methods).map(({ name, transfer_method_type: tmt, currency_code: cc }) => ({
          id: tmt,
          name,
          isFiat: CurrencyModel.isFiatCurrency(cc),
        }));

        return {
          uniqMethods,
          allMethods: res.transfer_methods,
        };
      } catch (e) {
        logger.error('Failed to fetch transfer-methods:\t', e);
        throw e;
      }
    },
    get: async (currencyCode?: string) => {
      if (!currencyCode) {
        throw new Error('Currency is missing');
      }

      const response = await WebRest.Funding.TransferMethods.get(currencyCode);
      const methods = response.transfer_methods;

      if (!methods.length) {
        return [];
      }

      const providers = methods.map((m) => new FundingProviderModel(m));

      return this.fetchFundingProviderFields(providers);
    },
    getFields: (id: FundingTransferMethodType) => WebRest.Funding.TransferMethods.getFields(id),
    findById: (transferMethodType: FundingTransferMethodType) => (method: FundingProviderModel) => method.id === transferMethodType,
    getProviders: (transferMethods: Array<BEFundingTransferMethod>): Array<FundingProviderModel> =>
      transferMethods.reduce((acc: Array<FundingProviderModel>, val) => {
        const index = acc.findIndex((method) => method.id === val.transfer_method_type);

        if (index >= 0) {
          acc[index]?.availableCurrencyCodes.push(val.currency_code);
        } else {
          acc.push(new FundingProviderModel(val));
        }

        return acc;
      }, []),
  };

  public transactions = {
    getAll: async (
      { from, to, currency, cursor, direction, transferMethod, status, maxPageSize = '20' },
      accountInfo: { mainAccountId?: string; isInstantAccountRequestor: boolean },
    ) => {
      const res = await WebRest.Funding.Transactions.getAll({
        from,
        to,
        cursor,
        direction,
        currency,
        transferMethod,
        status,
        maxPageSize,
      });
      const feTransactions: Array<TransactionModel> = res.transactions.map((e) => new TransactionModel(e, accountInfo));

      return {
        transactions: feTransactions,
        cursor: res.cursor,
      };
    },
    get: async (id: string) => WebRest.Funding.Transactions.get(id),
    getTypes: async (attempt = 0): Promise<Array<FundingTransferMethodType>> => {
      try {
        const res = await WebRest.Funding.Transactions.getTypes();

        return res.sort();
      } catch (e) {
        logger.error('Failed to fetch transfer-methods:\t', e);

        if (attempt < 3) {
          await retryService.waitForNextRetryTick();
          return this.transactions.getTypes(attempt + 1);
        }

        throw e;
      }
    },
  };

  public deposit = {
    fetch: async <T extends BEFiatDepositDetails | BEBlockchainDepositDetails>(paymentOptionId: FundingTransferMethodType, currencySymbol: string): Promise<T | undefined> => {
      try {
        return await (WebRest.Funding.Deposit.Details.get(currencySymbol, paymentOptionId) as Promise<T | undefined>);
      } catch (error) {
        if ((error as FundsAPIAccountDetailError).status === 404) {
          return undefined;
        }

        logger.warn(`Getting deposit details for currency: ${currencySymbol} and method: ${paymentOptionId} failed; retrying later`, error);
        await retryService.waitForNextRetryTick();
        return this.deposit.fetch(paymentOptionId, currencySymbol);
      }
    },
    create: (symbol: string) => WebRest.Funding.Deposit.Addresses.post(symbol),
  };

  public withdraw = {
    initiate: (data: { payoutAccount: string; currencySymbol: string; amount: string; authCode: string; type: FundingTransferMethodType }) =>
      WebRest.Funding.Withdraw.initiate({
        payout_account_id: data.payoutAccount,
        currency_code: data.currencySymbol,
        amount: data.amount,
        auth_code: data.authCode,
        type: data.type,
      }),
    confirm: (transactionId: string, secret: string) => WebRest.Funding.Withdraw.confirm({ transactionId, secret }),
    cancel: (transactionId: string) => WebRest.Funding.Withdraw.cancel({ transactionId }),
  };
}

const fs = new FundsService();

export default fs;
