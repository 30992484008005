import getBalance from '@exchange/helpers/balance';
import BigNumber from '@exchange/helpers/bignumber';
import { BalanceModel } from '@exchange/libs/balances/service/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { CurrencyModel } from '@exchange/libs/utils/currency/src';

export enum SubaccountType {
  STANDARD = 'STANDARD',
  INSTANT_TRADE = 'INSTANT_TRADE',
}

export enum SubaccountState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'CLOSED',
  PENDING_CREATE = 'PENDING_CREATE',
  PENDING_CLOSE = 'PENDING_CLOSE',
}

/* eslint-disable camelcase */
export interface BESubaccountBalance {
  account_id: string;
  available: string;
  currency_code: string;
  locked: string;
  sequence: number;
  time: string;
}

export interface BESubaccount {
  account_id: string;
  type: SubaccountType;
  state: SubaccountState;
  name: string;
  created: string;
  balances: Array<BESubaccountBalance>;
}
/* eslint-enable camelcase */

const getNotNaNNumber = (v: BigNumber) => (v.isNaN() ? new BigNumber(0) : v);

export const calculateTotalBalanceInFiat = (balances: Array<BalanceModel>, currencies: Dictionary<CurrencyModel> | undefined) => {
  const startingPoint = {
    all: new BigNumber(0),
    locked: new BigNumber(0),
  };

  if (!currencies) {
    return startingPoint;
  }

  const a = balances.reduce((acc, balance) => {
    const currency = currencies?.[balance.currencyCode] || {
      defaultCryptoValue: 0,
      defaultFiatValue: 0,
      name: undefined,
      precision: CONSTANTS.PRECISION.DEFAULT_UNKNOWN_CURRENCY,
    };

    const { totalFiatValue } = balance.getTotalIn(currency);
    const { lockedFiatValue } = balance.getLockedIn(currency);

    acc.all = acc.all.plus(getNotNaNNumber(totalFiatValue));
    acc.locked = acc.locked.plus(getNotNaNNumber(lockedFiatValue));

    return acc;
  }, startingPoint);

  return a;
};

export class SubaccountSimple {
  public accountId!: string;

  public type: SubaccountType;

  public state = SubaccountState.ACTIVE;

  public name!: string;

  public created!: string;

  public balances: Array<BalanceModel> = [];

  constructor({ balances, name, accountId, type }: { balances?: Array<BalanceModel>; name?: string; accountId: string; type: SubaccountType }) {
    this.balances = balances || [];
    this.created = new Date().toString();
    this.name = name || '';
    this.accountId = accountId;
    this.type = type;
  }

  get isInstantTradeAccount() {
    return this.type === SubaccountType.INSTANT_TRADE;
  }

  public setBalances = (balance: Array<BalanceModel>) => {
    this.balances = balance;
  };

  public setName = (name: string) => {
    this.name = name;
  };

  public getBalanceTotal = (currencies: Dictionary<CurrencyModel> | undefined) => calculateTotalBalanceInFiat(this.balances, currencies);
}
export default class Subaccount extends SubaccountSimple {
  get isActive() {
    return this.state === SubaccountState.ACTIVE;
  }

  get isInactive() {
    return this.state === SubaccountState.INACTIVE || this.state === SubaccountState.PENDING_CLOSE;
  }

  constructor(fields: BESubaccount) {
    super({
      name: fields.name,
      accountId: fields.account_id,
      type: fields.type,
    });
    this.state = fields.state;
    this.name = fields.name;
    this.created = fields.created;
    this.balances = (fields.balances || []).map((e) => new BalanceModel(e));

    if (this.name === CONSTANTS.MAIN_SUBACCOUNT_NAME_BE) {
      this.name = CONSTANTS.MAIN_SUBACCOUNT_NAME;
    }
  }

  public getTotalBalances = () => {
    const startingPoint = {
      all: new BigNumber(0),
      available: new BigNumber(0),
      locked: new BigNumber(0),
    };

    return this.balances.reduce((acc, balance) => {
      acc.all = acc.all.plus(getNotNaNNumber(balance.getTotal()));
      acc.available = acc.available.plus(getNotNaNNumber(balance.available));
      acc.locked = acc.locked.plus(getNotNaNNumber(balance.locked));

      return acc;
    }, startingPoint);
  };
}

export const getAggregatedBalance = (subaccounts: Array<Subaccount>, currencies: Dictionary<CurrencyModel> | undefined) => {
  const summary = subaccounts.reduce(
    (acc, account) => {
      account.balances.forEach((balance) => {
        const currentBalance = acc[balance.currencyCode];
        const currency = currencies?.[balance.currencyCode] || {
          precision: CONSTANTS.PRECISION.DEFAULT_UNKNOWN_CURRENCY,
        };

        const available = new BigNumber(getBalance({ v: balance.available, precision: currency.precision }));
        const locked = new BigNumber(getBalance({ v: balance.locked, precision: currency.precision }));

        if (currentBalance) {
          currentBalance.available = currentBalance.available.plus(available);
          currentBalance.locked = currentBalance.locked.plus(locked);
        } else {
          acc[balance.currencyCode] = new BalanceModel({
            locked,
            available,
            currency_code: balance.currencyCode,
            sequence: balance.sequence,
          });
        }
      });

      return acc;
    },
    {} as { [currencyName: string]: BalanceModel },
  );

  return Object.values(summary);
};
