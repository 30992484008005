<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import SimpleToast from '../SimpleToast.vue';
import SimpleToastMessage from '../SimpleToastMessage.vue';

const props = defineProps({
  order: { type: Object, required: true },
  market: { type: Object, required: true },
});
const emit = defineEmits<{
  (e: 'remove'): void;
}>();

const { t } = useI18n({ useScope: 'global' });

const sideVariant = computed(() => (props.order.side === 'BUY' ? 'primary' : 'secondary'));
const sideClass = computed(() => (props.order.side === 'BUY' ? 'buy' : 'sell'));
const filled = computed(() => t('modules.myOrders.toasts.filled.title'));
const side = computed(() => t(`fundamentals.orders.side.${props.order.side.toLowerCase()}`).toUpperCase());
const amount = computed(() => t('modules.myOrders.toasts.filled.amount'));
const price = computed(() => t('modules.myOrders.toasts.filled.price'));
</script>

<template>
  <simple-toast
    class="order-filled-toast"
    :class="[`order-filled-toast--${sideClass}`]"
    :variant="sideVariant"
    :show-icon="false"
    @remove="emit('remove')"
  >
    <template #title>
      <div class="order-filled-toast__header">
        <div class="order-filled-toast__title">{{ filled }}</div>
        <div class="order-filled-toast__side">{{ side }}</div>
      </div>
    </template>
    <template #message>
      <simple-toast-message>
        <div class="order-filled-toast__label">{{ amount }}</div>
        <ot-number-display
          class="order-filled-toast__amount"
          :number="order.amount"
          :precision="order.getAmountPrecision(market)"
        />
        <div class="order-filled-toast__currency">{{ order.baseCurrencyCode }}</div>
      </simple-toast-message>
      <simple-toast-message v-if="!order.isMarketOrder">
        <div class="order-filled-toast__label">{{ price }}</div>
        <ot-number-display
          class="order-filled-toast__amount"
          :number="order.displayPrice"
          :precision="order.getMarketPrecision(market)"
        />
        <div class="order-filled-toast__currency">{{ order.quoteCurrencyCode }}</div>
      </simple-toast-message>
    </template>
  </simple-toast>
</template>

<style lang="scss">
.order-filled-toast {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title::after {
    margin: 0 4px;
    content: '-';
  }

  &__side {
    color: var(--filled-toast-side-color);
  }
}

.order-filled-toast--sell {
  --filled-toast-side-color: rgb(var(--v-theme-secondary));
}

.order-filled-toast--buy {
  --filled-toast-side-color: rgb(var(--v-theme-primary));
}
</style>
