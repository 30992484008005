<script lang="ts" setup>
import { computed, ref, type PropType } from 'vue';
import { inject } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { type ModalVariant } from '@exchange/libs/modals/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { CurrencyModel } from '@exchange/libs/utils/currency/src';

const props = defineProps({
  balance: { type: Object as PropType<BigNumber>, required: true },
  currency: { type: Object as PropType<CurrencyModel>, required: true },
  isSelected: { type: Boolean, default: false },
  isWithdraw: { type: Boolean, required: true },
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const eurValue = computed(() => (props.balance ? props.balance.times(props.currency.defaultFiatValue) : 0));
const disabled = computed(() => props.isWithdraw && props.balance.isEqualTo(0));
const coinDescription = computed(() => props.currency.getCurrencyName());
const defaultPrecision = {
  fiat: CONSTANTS.PRECISION.DEFAULT_FIAT,
};
const balanceMasked = inject('balanceMasked', ref(false));
</script>

<template>
  <div
    :class="['coin-select-item', `coin-select-item--${variant}`, { 'coin-select-item--selected': isSelected, 'coin-select-item--disabled': disabled }]"
    :testid="`${disabled ? 'disabled-' : ''}coin-select-item-${currency.id}`"
  >
    <x-logo
      class="coin-select-item__logo"
      :name="currency.id"
      type="crypto"
    />
    <div class="coin-select-item__left">
      <div class="coin-select-item__title coin-select-item__title--left">
        {{ currency.id }}
      </div>
      <div
        v-if="coinDescription"
        class="coin-select-item__description coin-select-item__description--left"
      >
        {{ coinDescription }}
      </div>
    </div>
    <div
      v-if="isWithdraw"
      class="coin-select-item__right"
    >
      <ot-balance
        class="coin-select-item__title"
        :value="balance"
        :precision="currency.precision"
        :masked="balanceMasked"
      />
      <ot-balance
        class="coin-select-item__description"
        :value="eurValue"
        :precision="defaultPrecision.fiat"
        :masked="balanceMasked"
      />
    </div>
  </div>
</template>

<style lang="scss">
.coin-select-item {
  --coin-select-item-background-color: transparent;
  --coin-select-item-title-color: var(--text-0);
  --coin-select-item-description-color: rgb(var(--v-theme-text-tertiary));

  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: row;
  padding: 8px;
  background-color: var(--coin-select-item-background-color);
  cursor: pointer;

  &__logo {
    width: 32px;
    height: 32px;
    flex: 0 0 auto;
    margin-right: 10px;
  }

  &__title {
    color: var(--coin-select-item-title-color);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);

    &--left {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
    }
  }

  &__description {
    color: var(--coin-select-item-description-color);

    &--left {
      width: max-content;
    }
  }

  &__left {
    display: flex;
    flex: 1 0 30%;
    flex-direction: column;
    justify-content: center;
  }

  &__right {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.coin-select-item:hover,
.coin-select-item--selected {
  --coin-select-item-background-color: var(--elevation-light-2);

  border-radius: var(--app-border-radius);
}

.coin-select-item--dark {
  --coin-select-item-title-color: rgb(var(--v-theme-text-primary));
  --coin-select-item-description-color: rgb(var(--v-theme-text-secondary));
}

.coin-select-item--dark.coin-select-item:hover,
.coin-select-item--dark.coin-select-item--selected {
  --coin-select-item-background-color: rgb(var(--v-theme-elevation-2));
}

.coin-select-item.coin-select-item--disabled {
  > * {
    opacity: 0.2;
  }
}
</style>
