import dateIsInTimeRange from '@exchange/helpers/date-is-in-time-range';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';

const settlementDelayInfoShow = () => {
  const { start, end } = launchdarkly.flags.getSettlementDelay();
  const isTime = dateIsInTimeRange(start, end, new Date());

  return isTime;
};

export default settlementDelayInfoShow;
