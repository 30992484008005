import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import BigNumber from '@exchange/helpers/bignumber';
import { endOfCurrentDay, startOfCurrentDayMinus } from '@exchange/helpers/time-offset';
import { accountService } from '@exchange/libs/account/service/src';
import usePromise from '@exchange/libs/composables/shared/src/lib/usePromise';
import { fundsService, FundingTransferStatus, FundingTransferDirection } from '@exchange/libs/transactions/funds/service/src';
import { type TransactionModel } from '@exchange/libs/transactions/funds/service/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

const useCoverForBackend = (currency: { id: string }) => {
  const { t } = useI18n({ useScope: 'global' });

  const pendingAmount = ref(new BigNumber(0));

  const warningMessage = computed(() => t('modules.transactions.errors.amountIsTooHigh'));

  const getPendingTransactions = async (
    existingCursor?: string,
    pendingT = [] as TransactionModel[],
  ): Promise<{ transactions: TransactionModel[]; cursor: string | undefined }> => {
    const { accountIdHolder } = accountService;
    const status = `${FundingTransferStatus.PENDING},${FundingTransferStatus.INITIATED}`;
    const from = startOfCurrentDayMinus(CONSTANTS.HISTORY_DROPDOWN_NUMBER_OF_MONTHS_SELECTED);
    const to = endOfCurrentDay();

    const { transactions, cursor } = await fundsService.transactions.getAll(
      {
        from,
        to,
        direction: FundingTransferDirection.OUTGOING,
        status,
        currency,
        cursor: existingCursor,
        transferMethod: undefined,
        maxPageSize: '100',
      },
      { mainAccountId: accountIdHolder.value, isInstantAccountRequestor: false },
    );

    const updatedT = pendingT.concat(transactions);

    if (cursor) {
      return getPendingTransactions(cursor, updatedT);
    }

    return {
      transactions: updatedT,
      cursor,
    };
  };

  const getPendingAmount = (pending: Array<TransactionModel>) =>
    pending.reduce((acc, transaction) => {
      // eslint-disable-next-line no-param-reassign
      acc = acc.plus(new BigNumber(transaction.amount));
      return acc;
    }, new BigNumber(0));

  const { loading: loadingPendingTransactions, createPromise: getTransactionsNow } = usePromise(async () => {
    const { transactions } = await getPendingTransactions().catch(() => ({ transactions: [] }));

    pendingAmount.value = getPendingAmount(transactions);
  });

  getTransactionsNow();

  return {
    loadingPendingTransactions,
    pendingAmount,
    warningMessage,
  };
};

export default useCoverForBackend;
